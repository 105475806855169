import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import hiringImg from "../../../asset/images/hiring.png";

const Hiring = () => {
  const navigate = useNavigate();
  const openGmailCompose = () => {
    const receiverEmail = "connect@resolabindia.com";
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
    window.open(mailtoLink);
  };
  return (
    <>
      <div className="w-[95%] relative bg-white h-auto overflow-hidden text-5xl text-black flex flex-col gap-[40px] text-center">
        <div className="w-full rounded-11xl overflow-hidden bg-[#eaeaea] pb-7">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
            HIRING MADE EASY
          </h1>
          <div className="flex flex-col lg:flex-row w-[95%] mx-auto bg-white rounded-md shadow-lg overflow-hidden text-black">
            {/* Left Box */}
            <div className="lg:w-1/2 w-full bg-white p-6">
              <div className="rounded-2xl p-4 text-center">
                <h1 className="text-[1.5rem] sm:text-[1.4rem] font-bold text-[#61B847]">
                  <u>AUTOMATED HIRING</u>
                </h1>
              </div>
              <div className="text-center mt-4 text-[1.4rem] sm:text-[1rem]">
                <div>
                  <img src={hiringImg} alt="Hiring" className="h-[90%] w-[90%]" />
                </div>
                <h2 className="text-black px-5 text-[1.4rem] sm:text-[1rem]">
                  Directly hire from{" "}
                  <a
                    href="https://www.resolabindia.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.resolabindia.com
                  </a>
                </h2>
              </div>
            </div>

            {/* Vertical Line */}
            <div className="hidden lg:block w-[1px] bg-gray-400"></div>

            {/* Right Box */}
            <div className="lg:w-1/2 w-full bg-white p-6">
              <div className="rounded-2xl p-4 text-center">
                <h1 className="text-[1.5rem] sm:text-[1.4rem] font-bold text-[#015498]">
                  <u>CUSTOMIZE HIRING</u>
                </h1>
              </div>
              <div className="text-center mt-4 text-[1.4rem] sm:text-[1rem]">
                <div className="text-left w-[70%] mx-auto">
                  <p className="mb-4 ">
                    We also provide customize hiring solutions to MFI based on
                    their specific requirement.
                  </p>
                  
                    <p className="mb-4 ">Benefits of Customize Hiring:</p>

                    {/* Benefits List */}
                    <ul className="list-disc list-inside space-y-2 text-black font-large text-xl ">
                      <li>Access to broader talent pool</li>
                      <li>Expertise in MFI specific talent acquisition</li>
                      <li>Time and cost saving</li>
                      <li>Market Insights</li>
                      <li>Risk Mitigation</li>
                    </ul>
                  

                  <p className="mb-4"> Mail to:
                  <a
                      href="mailto:connect@resolabindia.com"
                      onClick={openGmailCompose}
                      className="text-blue-500 underline ml-2"
                    >
                      connect@resolabindia.com
                    </a>{" "}
                    for more.
                    </p>
                  
                </div>

              </div>
            </div>
          </div>

          {/* Get Started Button (Outside Both Boxes) */}
          <div
            className="flex justify-center items-center py-5"
            style={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <Button
              className="rounded-3xs bg-forestgreen py-[15px] px-[50px] text-5xl text-white"
              sx={{
                textTransform: "none",
                "&:hover": { background: "#015498" },
              }}
              onClick={() => navigate("/resourceavailable")}
            >
              Get Started
            </Button>
          </div>

        </div>
      </div>
    </>
  );
};

export default Hiring;
