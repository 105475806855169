import React from 'react'
import { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbar';
import "../ResourceRequire/sections/section1.css"

const VerifyJob = () => {
    // State to store parameters
    const [param1, setParam1] = useState('');
    const [param2, setParam2] = useState('');

    // Function to get parameters from the URL
    const getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    useEffect(() => {
        // Get parameters from the URL
        const parameter1 = getParameterByName('param1');
        const parameter2 = getParameterByName('param2');

        // Update state with parameters
        setParam1(parameter1);
        setParam2(parameter2);
    }, []); // Empty dependency array ensures this runs only once on component mount

   
    return (
        <>
            <Navbar />
            <div className="container d-flex align-items-center justify-content-center vh-100 ">
                <div className="text-center">

                    {/* <p>Parameter 1: {param1}</p>
                    <p>Parameter 2: {param2}</p> */}
                    <div className="resource-card-up d-flex justify-content-center align-items-center flex-column flex-md-row flex-lg-row ">
                       <h2>Thank You for verifying the Job.</h2>
                    </div>
                    {/* <div className='container mt-5'>
                        <h3>Do you want to verify this job?</h3>
                        <div className="verification-buttons mt-3">
                            <button className="btn btn-success mx-2" onClick={() => handleVerification(true)}>
                                Yes
                            </button>
                            <button className="btn btn-danger mx-2" onClick={() => handleVerification(false)}>
                                No
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default VerifyJob
