import axios from "axios";
import { config } from "./config";
import { getCookie } from "./cookies";
import _ from "lodash";

// Create Axios instance
var axiosInstance = axios.create({
  baseURL: config.API_URL, // Ensure baseURL is correct
});

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use((request) => {
  const token = getCookie("authToken"); // Ensure getCookie is fetching the correct token
  if (token) {
    request.headers.Authorization = `Bearer ${token}`; // Add Bearer token to headers
  }
  return request;
}, error => {
  // Handle request errors
  return Promise.reject(error);
});

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data; // Return only the data, not the entire response object
  },
  async (error) => {
    // Handle various types of errors
    const errorObject = {
      code: error.response?.status || 500, // Default to 500 if status is not available
      message: "Server not responding",
    };

    if (error.response) {
      // Handle specific HTTP error statuses
      if (error.response.status === 403) {
        errorObject.message = "Forbidden: You don't have permission to access this resource";
      } else if (error.response.status === 401) {
        errorObject.message = "Unauthorized: Token may be expired or invalid";
      } else if (error.response.status === 500) {
        errorObject.message = "Server Error: Please try again later";
      } else {
        errorObject.message = error.response.data?.message || "An error occurred";
      }
      return Promise.reject(errorObject);
    } else if (error.request) {
      // Handle network errors
      errorObject.message = "Network error: No response from server";
      return Promise.reject(errorObject);
    } else {
      // Handle unknown errors
      errorObject.message = "An unknown error occurred";
      return Promise.reject(errorObject);
    }
  }
);

export default axiosInstance;
