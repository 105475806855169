import React, { useState } from "react";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./SignupPopup.css"; // Ensure this CSS file is imported

const SignupPopup = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [jobText, setJobText] = useState("I'M LOOKING FOR A JOB");
  const [talentText, setTalentText] = useState("I WANT TO HIRE A TALENT");

  const handleJobClick = () => {
    navigate("/signuppage", { state: { type: "provider" } }); // Navigate to signup with "provider" type
  };

  const handleTalentClick = () => {
    navigate("/signuppage", { state: { type: "seeker" } }); 
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-container" onClick={onClose}>
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <span className="modal-title">Signup</span>
            <span className="modal-close" onClick={onClose}>
              X
            </span>
          </div>
          <div className="modal-body">
            <div
              className="modal-item job"
              onMouseEnter={() => setJobText("RESOURCE PROVIDER")}
              onMouseLeave={() => setJobText("I'M LOOKING FOR A JOB")}
              onClick={handleJobClick}
            >
              <h6 className="modal-text">{jobText}</h6>
            </div>
            <div
              className="modal-item talent"
              onMouseEnter={() => setTalentText("RESOURCE SEEKER")}
              onMouseLeave={() => setTalentText("I WANT TO HIRE A TALENT")}
              onClick={handleTalentClick}
            >
              <h6 className="modal-text">{talentText}</h6>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignupPopup;
