import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getProviderProfileDetails } from "../../redux/slices/providerCardSlice";
import SectionLoader from "../../layout/SectionLoader/SectionLoader";
import "../Price/Price.css";
import Navbar2 from "../../components/navbarhome";
import Hiring from "./Hiring/Hiring";
import Assessment from "./Assessment/Assessment";
import Verification from "./Verification/Verification";
import Advisory from "./Advisory/Advisory";
import Footer from "../../components/footer"; // Assuming Footer is the footer component you're using

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("");  // Default section is "hiring"

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const providerCardsArray = useSelector((state) =>
    _.get(state, "reducer.providerCard", {})
  );

  useEffect(() => {
    if (_.get(userDetail, "user.id", "")) {
      dispatch(getProviderProfileDetails(_.get(userDetail, "user.id", "")));
    }
  }, [dispatch, userDetail]);

  // Update the current section when the component is mounted or the location state changes
  useEffect(() => {
    if (location.state?.section) {
      setCurrentPage(location.state.section);  // Set the section passed via state
    } else {
      const params = new URLSearchParams(location.search);
      const section = params.get("section");
      if (section) {
        setCurrentPage(section);  // Set the section from the URL query params
      }
    }
  }, [location.state, location.search]);

  const handlePageChange = (page) => {
    setLoading(true);
    setTimeout(() => {
      setCurrentPage(page);
      navigate(`/product`, { state: { section: page } });  // Pass section via location.state
      setLoading(false);
    }, 1000);
  };

  const renderContent = () => {
    if (loading) {
      return <SectionLoader />;
    }
    switch (currentPage) {
      case "hiring":
        return <Hiring />;
      case "assessment":
        return <Assessment />;
      case "verification":
        return <Verification />;
      case "advisory":
        return <Advisory />;
      default:
        return null;  // No content if no valid section
    }
  };

  return (
    <>
      <Navbar2 />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[40px]">
        <section className="self-stretch bg-darkslateblue-100 flex flex-col items-center justify-center pt-0 lg:px-5 box-border text-center text-45xl text-white font-dosis lg:pt-24">
        <div className="w-full px-[45px] box-border">
      <div className="flex flex-col items-center justify-center relative max-w-full gap-[40px]">
        {/* Add the image here */}
        <img
          className="lg:h-[350px] w-[full] absolute bottom-[0px] left-[-50px] z-[1] h-[0px]"
          loading="lazy"
          alt=""
          src="/image-1@2x.png"
        />
              <div className="lg:py-[80px] py-[30px] flex flex-col items-center justify-center gap-[20px] z-[2]">
                <h3 className="m-0 text-heading font-bold z-[2]">Our Products</h3>
                <div className="mt-1 button-container">
                  <Button
                    className={`nav-button mx-1 w-[150px] sm:w-[100px] h-12 text-white ${currentPage === "hiring" ? "active" : ""}`}
                    onClick={() => handlePageChange("hiring")}
                  >
                    Hiring
                  </Button>
                  <Button
                    className={`nav-button mx-1 w-[150px] sm:w-[200px] h-12 text-white ${currentPage === "assessment" ? "active" : ""}`}
                    onClick={() => handlePageChange("assessment")}
                  >
                    Assessment
                  </Button>
                  <Button
                    className={`nav-button mx-1 w-[150px] sm:w-[200px] h-12 text-white ${currentPage === "verification" ? "active" : ""}`}
                    onClick={() => handlePageChange("verification")}
                  >
                    Verification
                  </Button>
                  <Button
                    className={`nav-button mx-1 w-[150px] sm:w-[150px] h-12 text-white ${currentPage === "advisory" ? "active" : ""}`}
                    onClick={() => handlePageChange("advisory")}
                  >
                    Advisory
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {renderContent()}

        {/* Footer Section with buttons to navigate to specific sections */}
        <Footer>
          <Button
            onClick={() => handlePageChange("hiring")}
            className={`footer-button ${currentPage === "hiring" ? "active" : ""}`}
          >
            Hiring
          </Button>
          <Button
            onClick={() => handlePageChange("assessment")}
            className={`footer-button ${currentPage === "assessment" ? "active" : ""}`}
          >
            Assessment
          </Button>
          <Button
            onClick={() => handlePageChange("verification")}
            className={`footer-button ${currentPage === "verification" ? "active" : ""}`}
          >
            Verification
          </Button>
          <Button
            onClick={() => handlePageChange("advisory")}
            className={`footer-button ${currentPage === "advisory" ? "active" : ""}`}
          >
            Advisory
          </Button>
        </Footer>
      </div>
    </>
  );
};

export default Product;
