import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import _ from "lodash";
import "./SignupPage.css";
import { useDispatch, useSelector } from "react-redux";
import { getDistrict } from "../../../redux/slices/providerCardSlice";
import Swal from "sweetalert2";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import OTPInput from "otp-input-react";
import { Button } from "@mui/material";
import { providerSignUpService } from "../../../services/authService";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ProviderSignup = () => {
  const [homeStateId, setHomeStateId] = useState("");
  const [homestateName, setHomeStateName] = useState("");
  const [homeDistrictId, setHomeDistrictId] = useState("");
  const [homedistrictName, setHomeDistrictName] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");

  const [getOtpFlag, setGetOtpFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [verificationResponse, setVerificationResponse] = useState("");
  const [sendOTPloading, setSendOTPloading] = useState(false);
  const [otpFormVisible, setOtpFormVisible] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );
  const dispatch = useDispatch();

  const handleIndustryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedIndustry(selectedValue);
    if (selectedValue !== "Others") {
      setOtherIndustry("");
    }
  };

  // Handle State Selection
  const handleDistrict = (stateId, stateName) => {
    setHomeStateId(stateId);
    setHomeStateName(stateName);
    dispatch(getDistrict(stateId));
    setTouchedFields((prev) => ({ ...prev, homestate: true }));
    setHomeDistrictId(""); // Reset district selection when state changes
    setHomeDistrictName(""); // Reset district name
    setErrors((prev) => ({
      ...prev,
      homestate: stateId ? "" : "State is required.",
    }));
  };

  // Handle District Selection
  const handleDistrictSelection = (districtId, districtName) => {
    setHomeDistrictId(districtId);
    setHomeDistrictName(districtName);
    setTouchedFields((prev) => ({ ...prev, homedistrict: true }));
    setErrors((prev) => ({
      ...prev,
      homedistrict: districtId ? "" : "District is required.",
    }));
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const dob = new Date(birthDate);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };
  const handleBlur = (field, value) => {
    if (field === "firstName") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name is required.",
        }));
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        firstName: true,
      }));
    }

    if (field === "lastName") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name is required.",
        }));
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        lastName: true,
      }));
    }

    // Validate email
    if (field === "email" && !value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        email: true,
      }));
    } else if (field === "email" && !/\S+@\S+\.\S+/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email.",
      }));
    }

    if (field === "phoneNumber") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number is required.",
        }));
      } else if (!/^\d{10}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        phoneNumber: true,
      }));
    }

    // Validate birthdate
    if (field === "birthdate") {
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          birthdate: "Date of Birth is required.",
        }));
        setTouchedFields((prevFields) => ({
          ...prevFields,
          birthdate: true,
        }));
      } else if (calculateAge(value) < 18) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          birthdate: "You must be at least 18 years old.",
        }));
        setTouchedFields((prevFields) => ({
          ...prevFields,
          birthdate: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          birthdate: "",
        }));
      }
    }

    if (field === "password") {
      if (!value.trim()) {
        // Show only "Password is required" when the field is empty
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password is required.",
        }));
      } else {
        // Initialize error messages
        const errorMessages = [];

        // Validation rules
        if (!/[A-Z]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one uppercase letter."
          );
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one special character."
          );
        }
        if (!/\d/.test(value)) {
          errorMessages.push("Password must contain at least one number.");
        }
        if (!/[a-zA-Z]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one alphabetical letter."
          );
        }

        // Set errors or clear them
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.join(" "),
        }));
      }

      setTouchedFields((prevFields) => ({
        ...prevFields,
        password: true,
      }));
    }

    // Validate confirm password
    if (field === "confirmPassword" && !value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Confirm password is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        confirmPassword: true,
      }));
    } else if (field === "confirmPassword" && value !== password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    }

    // Validate industry
    if (field === "industry" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "Industry selection is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        industry: true,
      }));
    } else if (
      field === "otherIndustry" &&
      selectedIndustry === "Others" &&
      !value.trim()
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otherIndustry: "Please specify your industry.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        otherIndustry: true,
      }));
    }

    // Validate homestate
    if (field === "homestate" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        homestate: "State selection is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        homestate: true,
      }));
    }

    // Validate homedistrict
    if (field === "homedistrict" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        homedistrict: "District selection is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        homedistrict: true,
      }));
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file size exceeds 2MB (2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        setErrors({ image: "Please select an image smaller than 2MB." });
        setImage(null); // Clear the file input
      } else {
        setErrors({}); // Clear the error
        setImage(file); // Set the selected image
      }
    }
  };
  const handleChange = (field, value) => {
    if (field === "firstName") {
      setFirstName(value);
      if (!/^[A-Za-z]*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
    }

    if (field === "lastName") {
      setLastName(value);
      if (!/^[A-Za-z]*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
    }

    if (field === "email") {
      setEmail(value);
      if (errors.email) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
    if (field === "phoneNumber") {
      setPhoneNumber(value);

      // Allow only digits
      if (!/^\d*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number must contain only digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }
    if (field === "birthdate") {
      setBirthdate(value);

      // Clear the error dynamically when a valid value is entered
      if (errors.birthdate && calculateAge(value) >= 18) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          birthdate: "",
        }));
      }
    }
    if (field === "password") {
      setPassword(value);

      // If the password field is empty, show only the "Password is required" error
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password is required.",
        }));
        return;
      }

      // Initialize error messages
      const errorMessages = [];

      // Validation rules
      if (!/[A-Z]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one uppercase letter."
        );
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one special character."
        );
      }
      if (!/\d/.test(value)) {
        errorMessages.push("Password must contain at least one number.");
      }
      if (!/[a-zA-Z]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one alphabetical letter."
        );
      }

      // Set errors or clear them
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: errorMessages.join(" "),
      }));
    }

    if (field === "confirmPassword") {
      setConfirmPassword(value);
      if (errors.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }
    }

    if (field === "industry") {
      setSelectedIndustry(value);

      // Clear the error for industry
      if (errors.industry) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          industry: "",
        }));
      }
    } else if (field === "otherIndustry") {
      setOtherIndustry(value);

      // Clear the error for otherIndustry
      if (errors.otherIndustry) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          otherIndustry: "",
        }));
      }
    }
    if (field === "homestate") {
      setHomeStateId(value);

      // Clear the error for state
      if (errors.homestate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          homestate: "",
        }));
      }

      // Reset district if state changes
      setHomeDistrictId("");
    } else if (field === "homedistrict") {
      setHomeDistrictId(value);

      // Clear the error for district
      if (errors.homedistrict) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          homedistrict: "",
        }));
      }
    }
  };
 
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const handleChanges = (field, value) => {
    if (field === "password") {
      setPassword(value);
    } else if (field === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const navigateToSeekerSignup = () => {
    navigate("/signuppage", { state: { type: "seeker" } });
  };

  const navigateToLogin = () => {
    navigate("/loginpage");
  };

  const checkEmailAndPhoneNumberUniqueness = async () => {
    try {
      const response = await axiosInstance.post("/account/check/", {
        email: email,
        phone_number: phoneNumber,
      });
  
      console.log(response);
      if (response.message === 'Email and phone number are already registered') {
        toast.error("Email and phone number are already registered.");
        return false;
      }
  
      if (response.message === 'Email is already registered') {
        toast.error("Email is already registered");
        return false;
      }
  
      if (response.message === 'Phone number is already registered') {
        toast.error("Phone number is already registered");
        return false;
      }
  
      return true;
    } catch (error) {
      // Log the full error to the console for debugging
      console.error("Error occurred while checking email/phone:", error);
      
      // You can display more specific error details
      if (error.response) {
        // The request was made, but the server responded with an error
        console.error("Response error:", error.response.data);
        toast.error(`Error: ${error.response.data.message || 'Something went wrong'}`);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Request error:", error.request);
        toast.error("No response received from server.");
      } else {
        // Other errors
        console.error("Error message:", error.message);
        toast.error("An unknown error occurred.");
      }
  
      return false;
    }
  };  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Check for form validation errors
    if (Object.values(errors).some((err) => err)) {
      toast.error("Please fill all the form fields correctly.");
      return;
    }
  
    // First check email and phone number uniqueness
    const isUnique = await checkEmailAndPhoneNumberUniqueness();
    if (!isUnique) {
      return; // Stop further execution if the email or phone number is already taken
    }
  
    setOtpFormVisible(true);
    setGetOtpFlag(false);
    await getOtp(); // Call function to send OTP after checking uniqueness
  };
  

  const getOtp = async () => {
    setSendOTPloading(true);
    const phonePattern = /^[6-9]\d{9}$/;
    if (!phonePattern.test(phoneNumber)) {
      toast.error("Invalid phone number!");
      setSendOTPloading(false);
      return;
    }
    const formatPh = "+91" + phoneNumber;
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          { size: "invisible" }
        );
        await window.recaptchaVerifier.render();
      }
      const res = await signInWithPhoneNumber(
        auth,
        formatPh,
        window.recaptchaVerifier
      );
      if (res.verificationId) {
        setVerificationResponse(res);
        toast.success("OTP sent successfully!");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred.");
    } finally {
      setSendOTPloading(false);
    }
  };

  const onOTPVerify = async () => {
    if (otp.length === 6) {
      setLoading(true);
      try {
        const myres = await verificationResponse.confirm(otp);
        if (myres) {
          toast.success("OTP verified successfully!");
          setOtpFormVisible(false);
          await handleFormSubmission();
        }
      } catch (error) {
        toast.error("Invalid OTP!");
      }
      setLoading(false);
    } else {
      toast.error("Please enter the complete OTP.");
    }
  };

  const resendOtp = async () => {
    setSendOTPloading(true);
    await getOtp(); // Reuse the getOtp method to send the OTP again.
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setOtp(""); // Clear the OTP field when phone number changes
  };

  const handleFormSubmission = async () => {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("date_of_birth", birthdate);

    if (selectedIndustry === "Others") {
      formData.append("industry", "Others");
      formData.append("other_industry", otherIndustry);
    } else {
      formData.append("industry", selectedIndustry);
      formData.append("other_industry", null);
    }

    formData.append("home_state", homestateName);
    formData.append("home_district", homedistrictName);
    formData.append("password", password);
    formData.append("password2", confirmPassword);

    const profilePicInput = document.querySelector("#image");
    if (profilePicInput?.files?.length > 0) {
      const file = profilePicInput.files[0];

      // Check file size (limit to 2MB for example)
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        Swal.fire({
          title: "Image Too Large",
          text: "Profile picture size should not exceed 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Stop submission if file size is too large
      }

      formData.append("profile_pic_url", file);
    }

    try {
      const response = await providerSignUpService(formData);
      if (response.status === 201) {
        Swal.fire({
          title: "Signup Failed",
          text: "You have successfully signed up.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Signup Successful!",
          text: response.data?.message || "Unknown error occurred.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/loginpage");
        });
      }
    } catch (error) {
      console.error("Error Details:", error.response || error.message || error);

      if (error.response?.status === 413) {
        // Handle Payload Too Large Error
        Swal.fire({
          title: "Image Too Large",
          text: "The uploaded image size is too large. Please use a smaller image.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        const isEmailUsed =
          error.response?.data?.errors?.email?.[0] ===
          "This field must be unique.";
        const isPhoneNumberUsed =
          error.response?.data?.errors?.phone_number?.[0] ===
          "This field must be unique.";

        if (isEmailUsed && isPhoneNumberUsed) {
          Swal.fire({
            title: "Signup Failed",
            text: "Email and phone number are already used.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (isEmailUsed) {
          Swal.fire({
            title: "Signup Failed",
            text: "Email is already used.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (isPhoneNumberUsed) {
          Swal.fire({
            title: "Signup Failed",
            text: "Phone number is already used.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Signup Failed",
            text:
              error.response?.data?.message ||
              "There was an error during signup. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };

  return (
    <>
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2 className="signup-title">SIGNUP</h2>

        <div className="signup-form-group">
          <div className="form-field">
            <label htmlFor="firstName">First Name</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
              onBlur={(e) => handleBlur("firstName", e.target.value)}
              required
            />
            {touchedFields.firstName && errors.firstName && (
              <p className="error-text">{errors.firstName}</p>
            )}
          </div>

          <div className="form-field">
            <label htmlFor="lastName">Last Name</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
              onBlur={(e) => handleBlur("lastName", e.target.value)}
              required
            />
            {touchedFields.lastName && errors.lastName && (
              <p className="error-text">{errors.lastName}</p>
            )}
          </div>
        </div>

        <div className="signup-form-group">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => handleChange("email", e.target.value)}
              onBlur={(e) => handleBlur("email", e.target.value)}
              required
            />
            {touchedFields.email && errors.email && (
              <p className="error-text">{errors.email}</p>
            )}
          </div>

          <div className="form-field">
            <label htmlFor="phone">Phone No</label>
            <input
              id="phone"
              name="phone"
              type="text"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              onBlur={(e) => handleBlur("phoneNumber", e.target.value)}
              required
            />
            {touchedFields.phoneNumber && errors.phoneNumber && (
              <p className="error-text">{errors.phoneNumber}</p>
            )}
          </div>
        </div>

        <div className="signup-form-group">
        <div className="form-field">
      <label htmlFor="image">Upload Professional Image</label>
      <input
        id="image"
        name="image"
        type="file"
        onChange={handleImageChange}
        onBlur={(e) => handleBlur("image", e.target.files[0])}
        required
      />
      {errors.image && <p className="error-text">{errors.image}</p>}
    </div>
          <div className="form-field">
            <label htmlFor="birthdate">Date of Birth</label>
            <input
              id="birthdate"
              name="birthdate"
              type="date"
              value={birthdate}
              onChange={(e) => handleChange("birthdate", e.target.value)}
              onBlur={(e) => handleBlur("birthdate", e.target.value)}
              required
            />
            {touchedFields.birthdate && errors.birthdate && (
              <p className="error-text">{errors.birthdate}</p>
            )}
          </div>
        </div>

        <div className="signup-form-group">
          <div className="form-field">
            <label htmlFor="industry">Mention Your Current Industry</label>
            <select
              id="industry"
              name="industry"
              value={selectedIndustry}
              onChange={(e) => handleChange("industry", e.target.value)}
              onBlur={(e) => handleBlur("industry", e.target.value)}
              required
            >
              <option value="" disabled>
                Select your industry
              </option>
              <option value="Micro Finance Industry">
                Micro Finance Industry
              </option>
              <option value="BFSI">BFSI</option>
              <option value="Others">Others</option>
            </select>
            {touchedFields.industry && errors.industry && (
              <p className="error-text">{errors.industry}</p>
            )}
            {selectedIndustry === "Others" && (
              <div className="form-field mt-2">
                <label htmlFor="otherIndustry">Other Industry</label>
                <input
                  id="otherIndustry"
                  name="otherIndustry"
                  type="text"
                  placeholder="Enter your industry"
                  value={otherIndustry}
                  onChange={(e) =>
                    handleChange("otherIndustry", e.target.value)
                  }
                  onBlur={(e) => handleBlur("otherIndustry", e.target.value)}
                />
                {touchedFields.otherIndustry && errors.otherIndustry && (
                  <p className="error-text">{errors.otherIndustry}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="location-section">
          <label>Home Location</label>
          <div className="signup-form-group">
            {/* State Selection */}
            <div className="form-field">
              <select
                id="homestate"
                value={homeStateId}
                onChange={(e) =>
                  handleDistrict(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text
                  )
                }
                onBlur={() => handleBlur("homestate", homeStateId)}
              >
                <option value="">Select State</option>
                {_.map(_.get(provoiderCardsArray, "states", []), (stateObj) => (
                  <option
                    value={_.get(stateObj, "id", "")}
                    key={_.get(stateObj, "id", "")}
                  >
                    {_.get(stateObj, "state", "")}
                  </option>
                ))}
              </select>
              {touchedFields.homestate && errors.homestate && (
                <p className="error-text">{errors.homestate}</p>
              )}
            </div>

            {/* District Selection */}
            <div className="form-field">
              <select
                id="homedistrict"
                value={homeDistrictId}
                onChange={(e) =>
                  handleDistrictSelection(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text
                  )
                }
                onBlur={() => handleBlur("homedistrict", homeDistrictId)}
                disabled={!homeStateId} // Disabled until a state is selected
              >
                <option value="">Select District</option>
                {_.map(
                  _.get(provoiderCardsArray, "districts", []),
                  (districtObj) => (
                    <option
                      value={_.get(districtObj, "id", "")}
                      key={_.get(districtObj, "id", "")}
                    >
                      {_.get(districtObj, "district", "")}
                    </option>
                  )
                )}
              </select>
              {touchedFields.homedistrict && errors.homedistrict && (
                <p className="error-text">{errors.homedistrict}</p>
              )}
            </div>
          </div>
        </div>

        <div className="signup-form-group">
        <div className="form-field">
          <label htmlFor="password">Password</label>
          <div className="input-container">
            <input
              id="password"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => handleChange("password", e.target.value)}
              onBlur={(e) => handleBlur("password", e.target.value)}
              required
            />
            <button
              type="button"
              className="eye-icon"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
      </div>

      <div className="signup-form-group">
        <div className="form-field">
          <label htmlFor="confirmpassword">Confirm Password</label>
          <div className="input-container">
            <input
              id="confirmpassword"
              name="confirmpassword"
              type={isConfirmPasswordVisible ? "text" : "password"}
              placeholder="Enter confirm password"
              value={confirmPassword}
              onChange={(e) => handleChange("confirmPassword", e.target.value)}
              onBlur={(e) => handleBlur("confirmPassword", e.target.value)}
              required
            />
            <button
              type="button"
              className="eye-icon"
              onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
            >
              {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
      </div>
        <button
          type="submit"
          className="signup-button"
          disabled={sendOTPloading}
        >
          {sendOTPloading ? "Submitting..." : "SUBMIT"}
        </button>

        <div id="recaptcha-container"></div>

        <div className="navigate-link">
          If you are a Company (MFI), then{" "}
          <span onClick={navigateToSeekerSignup} className="link">
            <b>click here</b>
          </span>
          .
        </div>

        <div className="loginpage-link">
          Already have an account?{" "}
          <span onClick={navigateToLogin} className="link">
            <b>Login</b>
          </span>
          .
        </div>
      </form>

      <Modal
        isOpen={otpFormVisible}
        onRequestClose={() => setOtpFormVisible(false)}
        ariaHideApp={false}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          {/* Close Button (X) */}
          <span
            className="absolute top-2 right-2 text-10xl cursor-pointer text-gray-700"
            onClick={() => setOtpFormVisible(false)}
          >
            &times;
          </span>

          <div className="form-group py-2">
            <label htmlFor="phone" className="block text-sm font-medium mb-1">
              PHONE NUMBER
            </label>
            <div className="flex items-center">
              <PhoneInput
                className="w-full bg-[#F7F7F7] h-10 px-2 rounded-md border focus:outline-none focus:ring-2 focus:ring-indigo-500"
                defaultCountry="IN"
                placeholder="Enter Your Phone Number"
                value={phoneNumber}
                disabled
              />
              {getOtpFlag && (
                <div
                  id="buttonDiv"
                  className="btnOTP cursor-pointer px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  onClick={getOtp}
                >
                  {sendOTPloading ? "OTP sending..." : "Get OTP"}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="otp" className="block text-xl font-medium mb-1">
              ENTER OTP
            </label>
            <OTPInput
              className="w-full bg-[#F7F7F7] py-1 h-10 px-2 text-2xl rounded-md border border-blue-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
            />
          </div>

          <div className="flex justify-center w-full mt-5">
            <Button
              onClick={onOTPVerify}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </Button>
          </div>

          <div className="flex justify-center mt-4 text-xs text-gray-500">
            <span>
              Didn't get OTP?{" "}
              <span
                onClick={resendOtp}
                className="text-blue-500 cursor-pointer"
              >
                Resend OTP
              </span>
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProviderSignup;
