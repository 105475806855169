import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { set } from "lodash";
import Swal from "sweetalert2";
import { submitAssessment } from "../../../services/providerService";
import { useNavigate } from 'react-router-dom';

const Assessment = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [remainingTime, setRemainingTime] = useState(600); // 30 minutes in seconds

  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );
  const seekerCardsArray = useSelector((state) =>
    _.get(state, "reducer.seekerCard", {})
  );

  const navigate = useNavigate();

  // navigating back or reloading
  const handlePageNavigation = () => {
    Swal.fire({
      title: 'Are you sure you want to leave?',
      text: 'Your changes will be lost.',
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // The user clicked "Yes" in the SweetAlert dialog, allow navigation.
        window.removeEventListener('beforeunload', handlePageNavigation);
        window.history.back();
      }
    });
  };

  // Enable the page navigation warning when needed, e.g., when making unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // This is required for some browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);



  useEffect(() => {
    // Fetch questions from an API or import from a JSON file
    setQuestions(provoiderCardsArray.quizQuestions.data.questions);
  }, []);

  useEffect(() => {
    // Start the timer countdown using setInterval or setTimeout.
    // Update the remainingTime state every second.
    // Handle time expiration.
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    // Clear the timer when component unmounts or when time expires.
    if (remainingTime <= 0) {
      clearInterval(timer);
      // Handle time expiration logic here (e.g., submit the assessment).
      // You can also show a time-up message to the user.
      handleSubmit();
    }

    return () => clearInterval(timer);
  }, [remainingTime]);


  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    }
  };

  const handleSelectAnswer = (questionId, selectedOption) => {
    // console.log(questionId, selectedOption);
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedOption,
    }));
  };

  const handleSubmit = async () => {
    // console.log("Selected Answers:", selectedAnswers);
    // console.log(questions);
    const formattedAnswers = {
      id: provoiderCardsArray.quizQuestions.data.id,
      questions: questions.map((question) => ({
        id: question.id,
        choice_chosen: selectedAnswers[question.id] || 0,
      })),
    };
    // console.log(formattedAnswers);
    const response = await submitAssessment(formattedAnswers);


    if (response.status === 1) {
      Swal.fire({
        icon: "success",
        title: response.data.achieved_score + "/" + response.data.total_score,
        text: "You will be redirected to assessment page...",
        showCloseButton: false,
        confirmButtonText: '<i className="fa fa-thumbs-up"></i> Ok!',
      });

      setTimeout(() => {
        navigate('/assesement');
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: "You will be redirected to assessment page...",
        showCloseButton: false,
        confirmButtonText: '<i className="fa fa-thumbs-up"></i> Ok!',
      });
    }

  };
  const currentQuestionData = questions[currentQuestion];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-center ">
            <div className="card col-12 col-md-6 my-4" style={{ backgroundColor: 'lightblue' }}>
              <div className="card-body">
                {/* Display the current question */}
                <h3 className="card-title " style={{ color: "black" }}>{currentQuestion + 1}{". "} {currentQuestionData?.content}</h3>

                {/* Display options */}
                <div className="p-2 my-5 container" style={{background:"white"}}>
                  {currentQuestionData?.choices.map((option, index) => (
                    <div key={index} onClick={() => handleSelectAnswer(currentQuestionData.id, option.id)} className="form-check m-1 "style={{background: selectedAnswers[currentQuestionData.id] === option.id ?'lightgreen':"lightblue",border:'2px solid green'}} >
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`option-${currentQuestionData.id}-${option.id}`} // Unique ID for the radio input
                        name={`${currentQuestionData.id}`}
                        value={option.id}
                        style={{ display: 'none' }}
                        checked={selectedAnswers[currentQuestionData.id] === option.id}
                        onChange={() => handleSelectAnswer(currentQuestionData.id, option.id)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`option-${currentQuestionData.id}-${option.id}`} // Match the input's ID
                        style={{ cursor: "pointer", fontSize: "16px" }} // Custom styles for label
                      >
                        {index+1}{". "}{option.content}
                      </label>
                    </div>
                  ))}
                </div>


                {/* Display the timer */}
                <div className="text-center py-2">
                  <span className="font-weight-bold">
                    Time Remaining:{" "}
                  </span>
                  <span>
                    {Math.floor(remainingTime / 60)}:
                    {(remainingTime % 60).toString().padStart(2, "0")}
                  </span>
                  <i className="fa fa-bell ml-2" aria-hidden="true"></i>
                </div>

                {/* Previous and Next buttons */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <button
                    className="btn btn-primary"
                    onClick={handlePrevQuestion}
                    disabled={currentQuestion === 0}
                  >
                    Previous
                  </button>

                  {currentQuestion < questions.length - 1 && (
                    <button className="btn btn-primary" onClick={handleNextQuestion}>
                      Next
                    </button>
                  )}
                  {currentQuestion === questions.length - 1 && (
                    <button className="btn btn-success" onClick={handleSubmit}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





  );
};

export default Assessment;
