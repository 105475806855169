import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Assessment = () => {

  const navigate = useNavigate();

  const openGmailCompose = () => {
    const receiverEmail = 'connect@resolabindia.com';
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
    window.open(mailtoLink);
  };

  return (
    <>
      <div className="w-[95%] relative bg-white h-auto overflow-hidden text-5xl text-black flex flex-col gap-[40px] text-center">
        <div className="w-full rounded-11xl overflow-hidden bg-[#eaeaea] pb-10">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
            ASSESSMENTS TO EVALUATE TALENT
          </h1>
          <div className="flex flex-col lg:flex-row w-[95%] mx-auto bg-white rounded-md shadow-lg overflow-hidden">
            {/* Left Box */}
            <div className="lg:w-1/2 w-full bg-white p-6">
              <div className="p-4 text-center">
                <h1 className="text-[1.5rem] sm:text-[1.4rem] font-bold text-[#61B847]">
                  <u>Benefits of pre- employment  Assessment</u>
                </h1>
              </div>
              <div className="text-center mt-4 text-[1.4rem] sm:text-[1rem]">
                <div className="text-left mt-4">
                  <ul className="space-y-3">
                    <li >
                      <span className="font-bold">Improved Hiring Accuracy :</span>{" "}
                      Objectively evaluates a candidate's fit for the role.
                    </li>
                    <li>
                      <span className="font-bold">Reduced Turnover :</span>{" "}
                      Helps select candidates who align with the job and company culture.
                    </li>
                    <li>
                      <span className="font-bold">Time and Cost Efficiency :</span>{" "}
                      Filters out unsuitable candidates early in the process.
                    </li>
                    <li>
                      <span className="font-bold">Reduced Bias :</span>{" "}
                      Structured assessments minimize subjective decision-making.
                    </li>
                  </ul>
                </div>
                
              </div>
            </div>

            {/* Vertical Line */}
            <div className="hidden lg:block w-[1px] bg-gray-400"></div>

            {/* Right Box */}
            <div className="lg:w-1/2 w-full bg-white p-6">
              <div className="p-4 text-center">
                <h1 className="text-[1.5rem] sm:text-[1.4rem] font-bold text-[#015498]"><u>Benefits of post employment assessment
                </u></h1>
              </div>
              <div className="text-center mt-4 text-[1.4rem] sm:text-[1rem]">
                <div className="text-left">
                  <ul>
                    <li>
                      <span className="font-bold">Enhanced Performance :</span>{" "}
                      Helps identify areas for improvement and provides actionable feedback.

                    </li>
                    <li>
                      <span className="font-bold">Employee Development :</span>{" "}
                      Pinpoints training and development needs.
                    </li>
                    <li>
                      <span className="font-bold">Retention Improvement :</span>{" "}
                      Identifies and addresses factors that might lead to turnover.
                    </li>
                    <li>
                      <span className="font-bold">Succession Planning :</span>{" "}
                      Recognizes high-potential employees for future leadership roles.
                    </li>
                    <li>
                      <span className="font-bold">Informed Decision-Making :</span>{" "}
                      Provides data to support promotions, demotions, or role changes.
                    </li>
                  </ul>
                </div>
                {/* <div className="py-5">
                  <Button
                    className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                    sx={{
                      textTransform: "none",
                      "&:hover": { background: "#015498" },
                    }}
                    onClick={openGmailCompose}
                  >
                    Speak With Us
                  </Button>
                </div> */}
              </div>
              
            </div>
            
          </div>
          <div
            className="flex justify-center items-center py-5"
            style={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <Button
              className="rounded-3xs bg-forestgreen py-[15px] px-[50px] text-5xl text-white"
              sx={{
                textTransform: "none",
                "&:hover": { background: "#015498" },
              }}
              onClick={() => navigate("/assesement")}
            >
              EXPLORE 
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assessment;
