import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import providerCardReducer from "./slices/providerCardSlice";
import seekerCardReducer from "./slices/seekerCardSlice";
import authModalHandleReducer from "./slices/authModalHandleSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";

const reducers = combineReducers({
  auth: authReducer,
  provoiderCard: providerCardReducer, // Remove from blacklist to persist this
  seekerCard: seekerCardReducer,      // Remove from blacklist to persist this
  authModalHandle: authModalHandleReducer, // Decide based on your requirement
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "provoiderCard", "seekerCard"], // Include these reducers to persist them
  blacklist: ["authModalHandle"], // Keep this if you don't want it persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: {
    reducer: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
  