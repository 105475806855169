import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import PageLoader from "../layout/PageLoader/PageLoader";
import Team from "../pages/About/Team/Team.js";
import Career from "../pages/About/Career/Career";
import Product from "../pages/Product/Product";
import TestWindow from "../pages/Assesement/TestWindow.js";
import Partner from "../pages/About/Partner/Partner";
import VerifyJob from "../pages/VerifyJob/VerifyJob.js";
import FAQ from "../pages/FAQ/FAQ.js";
// import UploadDocs from "../pages/UploadDocs/UploadDocs.js";
import PaymentPage from "../pages/Price/PaymentPageComponent/PaymentPage.js";
import AboutUs from "../pages/About/AboutUs.js";
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function ProviderRouter() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/provider" replace />,
    },
    {
      path: "/",
      // element: <ProtectedRoute />,
      children: [
        { element: <Navigate to="/provider" replace />, index: true },
        { path: "home", element: <Home /> },
        { path: "resourceavailable", element: <ResourceAvailable /> },
        { path: "resourcerequire", element: <ResourceRequire /> },
        { path: "assesement", element: <Assesement /> },
        { path: "contact", element: <Contact /> },
        { path: "aboutus" , element: <AboutUs />},
        { path: "provider", element: <Provider /> },
        // { path: "seeker", element: <Seeker /> },
        { path: "general", element: <GeneralAptitude /> },
        { path: "providerdetail/:id", element: <ProviderDetail /> },
        { path: "seekerdetail/:id", element: <SeekerDetail /> },
        { path: "price", element: <Price /> },
        { path: "team", element: <Team /> },
        { path: "career", element: <Career /> },
        { path: "product", element: <Product /> },
        { path: "testwindow", element: <TestWindow /> },
        { path: "partner", element: <Partner /> },
        { path: "verifyJob", element: <VerifyJob /> },
        { path: "faq" , element: <FAQ />},
        { path: "payment" , element: <PaymentPage />}
      ],
    },
    { path: "*", element: <Navigate to="/provider" replace /> },
  ]);
}

// Dashboard
const Home = Loadable(lazy(() => import("../pages/Home/Home")));
const ResourceAvailable = Loadable(
  lazy(() => import("../pages/ResourceAvailable/ResourceAvailable"))
);
const ResourceRequire = Loadable(
  lazy(() => import("../pages/ResourceRequire/ResourceRequire"))
);
const Assesement = Loadable(
  lazy(() => import("../pages/Assesement/Assesement"))
);
const Contact = Loadable(lazy(() => import("../pages/Contact/Contact")));
const Provider = Loadable(lazy(() => import("../pages/Provider/Provider")));
const Seeker = Loadable(lazy(() => import("../pages/Seeker/Seeker")));
const ProviderDetail = Loadable(
  lazy(() => import("../pages/ProviderDetail/ProviderDetail"))
);
const SeekerDetail = Loadable(
  lazy(() => import("../pages/SeekerDetail/SeekerDetail"))
);
const GeneralAptitude = Loadable(
  lazy(() => import("../pages/Assesement/TestPages/GeneralAptitude.js"))
);
const Price = Loadable(lazy(() => import("../pages/Price/Price")));
