import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardContent, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux"; // Assuming Redux is used for auth
import "./PartnerPage.css"; // Import the CSS for styling

function PartnerPage() {
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.reducer.auth); // Access user details from Redux state

  const handlePayNow = (planName, price) => {
    // Allow navigation whether logged in or not
    navigate("/partnerpayment", { state: { planName, price } });
  };

  const PlanCard = ({ title, price, features, buttonText }) => {
    const cardClass = `plan-card card-${title.toLowerCase().replace(' ', '-')}`;

    return (
      <Card variant="outlined" className={cardClass}>
        <CardHeader title={title} className="Styled-pt-3 payment-card-title" />
        <hr />
        <CardContent>
          <Box className="Styled-px-2">
            {title === "Empanelment Cost *" && (
              <Typography variant="h3" gutterBottom>
                <span>{`₹${price}`}</span>
              </Typography>
            )}
            <ul className="partner-features-list">
              {features.map((feature, idx) => (
                <li key={idx}>
                  <Typography variant="subtitle1">{feature}</Typography>
                </li>
              ))}
            </ul>
          </Box>
          {title === "Empanelment Cost *" && (
            <Box className="text-center">
              <button className="button" onClick={() => handlePayNow(title, price)}>
                {buttonText}
              </button>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  const cards = [
    {
      title: "Eligibility",
      price: 0,
      features: ["18+ Age", "Functional Computer Skills", "Domicile of District", "Must have two Wheeler"],
      buttonText: "Choose Plan",
    },
    {
      title: "Empanelment Cost *",
      price: 25000,
      features: ["Experience", "Expertise", "Earning", "Knowledge"],
      buttonText: "Pay Now",
    },
    {
      title: "Partner Role",
      price: 0,
      features: ["Talent Onboarding", "Relationship Building", "Upskilling", "Background Verification"],
      buttonText: "Pay Now",
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {cards.map((card, index) => (
          <div key={index} className={`col-md-4 ${index === 1 ? "middle-card" : ""}`}>
            <PlanCard
              title={card.title}
              price={card.price}
              features={card.features}
              buttonText={card.buttonText}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartnerPage;
