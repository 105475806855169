import React from "react";

const Company = () => {
  return (
    <>
      <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px]  text-center">
        <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
            COMPANY
          </h1>

          <section className="relative py-0 box-border w-full text-21xl text-darkslateblue-100 text-center inline-block">
            <div className="flex flex-col items-center pb-[0px] justify-center gap-[15px] text-3xl ">
              <div className="lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-5 px-36 sm:px-8 bg-whitesmoke rounded-11xl z-[1]">
                <p className="text-[1.5rem] sm:text-[1rem] text-black text-left inline-block transform">
                  Resolab is a talent marketplace for the microfinance industry,
                  connecting leading organizations with the best resources.
                </p>
                <p className="text-[1.5rem] sm:text-[1rem] text-black text-left inline-block transform">
                  We believe that the right talent can transform the
                  microfinance industry, and we are here to make that happen.
                </p>
                <p className="text-[1.5rem] sm:text-[1rem] text-black text-left inline-block transform">
                  We are based at Ahmedabad and registered under companies Act
                  2013, as Enliven Solutions Pvt Ltd.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Company;
