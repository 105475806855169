import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _, { set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  editProviderProfileDetails,
  getProviderProfileDetails,
  getState,
} from "../redux/slices/providerCardSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  awsUpdateImage1,
  awsUpdateImage2,
} from "../services/providerService";
import { Loader } from "semantic-ui-react";
import { Button } from "@material-tailwind/react";

const ImageUploader = ({ providerPrfoile }) => {
  const dispatch = useDispatch();

  const [fieldVaue, setFieldValue] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [registerRegion, setRegisterRegion] = useState("");
  const [fileKey, setFilekey] = useState(null);
  const [loading, setLoading] = useState(false);

  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );
  const [date, setDate] = useState(
    _.get(provoiderCardsArray, "profile.date_of_birth", "")
  );

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));

  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("First name cannot be empty"),
    lastName: Yup.string().required("Last name cannot be empty"),
  });

  useEffect(() => {
    if (_.get(userDetail, "user.id", "")) {
      dispatch(getProviderProfileDetails(_.get(userDetail, "user.id", "")));
    }
    dispatch(getState());
    setFieldValue(_.get(provoiderCardsArray, "profile.profile_pic_url", ""));
    setOtherIndustry(_.get(provoiderCardsArray, "profile.industry", ""));
    setRegisterRegion(
      _.get(provoiderCardsArray, "profile.registered_region", "")
    );
  }, [dispatch]);

  const urlToFile = async (url, filename, mimeType) => {
    const res = await fetch(url, {
      method: "GET",
      headers: { "Cache-Control": "no-cache" },
    });
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  const getImageFile = async () => {
    if (!_.get(provoiderCardsArray, "profile.profile_pic_url", "")) {
      return;
    }
    let newfile1 = await urlToFile(
      `${_.get(provoiderCardsArray, "profile.profile_pic_url", "")}`,
      _.get(provoiderCardsArray, "profile.profile_pic_url", "")
    );
    setFieldValue(newfile1);
  };

  const initialValue = {
    firstName: _.get(provoiderCardsArray, "profile.first_name", ""),
    lastName: _.get(provoiderCardsArray, "profile.last_name", ""),
    email: _.get(provoiderCardsArray, "profile.email", ""),
    registered_region: _.get(
      provoiderCardsArray,
      "profile.registered_region",
      ""
    ),
    phoneno: _.get(provoiderCardsArray, "profile.phone_number", ""),
    industry: _.get(provoiderCardsArray, "profile.industry", ""),
    other_industry: _.get(provoiderCardsArray, "profile.other_industry", ""),
    date: _.get(provoiderCardsArray, "profile.date_of_birth", ""),
    profile_pic_url: _.get(provoiderCardsArray, "profile.profile_pic_url", "")
      ? urlToFile(
          `${_.get(provoiderCardsArray, "profile.profile_pic_url", "")}`
        )
      : "",
    file_key: _.get(provoiderCardsArray, "profile.profile_pic_url", "")
      ? decodeURIComponent(
          new URL(_.get(provoiderCardsArray, "profile.profile_pic_url", ""))
            .pathname
        )
      : "",
  };

  const changeImage = async (event) => {
    setLoading(true);
    // setFieldValue(event.target.files[0]);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileUrl = e.target.result;
        setFieldValue(fileUrl);
      };

      reader.readAsDataURL(file);
    }

    const data1 = {
      file_name: event.target.files[0].name,
      media_type: "profile_pic",
    };
    const res = await awsUpdateImage1(data1);
    let formData = new FormData();
    for (var key in res.data.fields) {
      formData.append(key, res.data.fields[key]);
    }
    formData.append("file", event.target.files[0]);
    const res1 = await awsUpdateImage2(formData);
    if (res1.status === 204) {
      setLoading(false);
      toast.success("Image uploaded successfully..!");
      setFilekey(res.data.fields.key);
    }
  };
  // console.log(provoiderCardsArray);
  const onSubmit = async (value) => {
    if (
      _.get(provoiderCardsArray, "profile.profile_pic_url", "") ||
      fieldVaue
    ) {
      let formData = new FormData();
      formData.append("first_name", _.get(value, "firstName", ""));
      formData.append("last_name", _.get(value, "lastName", ""));
      formData.append("industry", otherIndustry);
      formData.append("other_industry", _.get(value, "other_industry", ""));
      formData.append(
        "file_key",
        fileKey == null ? initialValue.file_key : fileKey
      );
      formData.append("registered_region", registerRegion);
      formData.append("date_of_birth", new Date(date));
      await dispatch(
        editProviderProfileDetails(_.get(userDetail, "user.id", ""), formData)
      );
      await dispatch(
        getProviderProfileDetails(_.get(userDetail, "user.id", ""))
      );
    } else {
      toast.error("Profile photo can not be empty..!");
    }
  };

  const handleRemoveImage = async () => {
    setFieldValue(null);
  };
  // console.log(fieldVaue);

  return (
    <div style={{ padding: "30px" }}>
      {loading && <Loader active inline="centered" />}
      <>
        {!_.get(provoiderCardsArray, "profile.profile_pic_url", "") && (
          <div className="alert alert-danger" role="alert">
            Please update your profile image
          </div>
        )}
        <Formik
          initialValues={initialValue}
          onSubmit={(values, { setSubmitting }) =>
            onSubmit(values, setSubmitting)
          }
          validationSchema={profileSchema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div>
                <label htmlFor="experince" className="font-bold">
                  Name
                </label>
                <div className="w-full flex lg:flex-row flex-col gap-[5px]">
                  <div className="w-full  mt-1">
                    <Field
                      name="firstName"
                      placeholder="First Name"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="text"
                    />
                    {errors.firstName && touched.firstName ? (
                      <div style={{ color: "red" }}>{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="w-full mt-1">
                    <Field
                      name="lastName"
                      placeholder="Last Name"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="text"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div style={{ color: "red" }}>{errors.lastName}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full flex lg:flex-row flex-col gap-[5px]">
                  <div className="w-full mt-2">
                    <label htmlFor="industry" className="font-bold">
                      Industry
                    </label>
                    <Field
                      as="select"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      name="industry"
                      value={otherIndustry}
                      onChange={(e) => setOtherIndustry(e.target.value)}
                    >
                      <option defaultChecked disabled value="">
                        Select Industry
                      </option>
                      <option value="Micro Finance Industry">
                        Micro Finance - BFSI
                      </option>
                      <option value="Other">Others</option>
                    </Field>
                    {!otherIndustry ? (
                      <div style={{ color: "red" }}>
                        Industry can not be empty
                      </div>
                    ) : null}
                  </div>
                  {otherIndustry === "Other" && (
                    <div className="w-full mt-2">
                      <label htmlFor="other_industry" className="font-bold">
                        Other Industry
                      </label>
                      <Field
                        name="other_industry"
                        className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        type="text"
                        placeholder="Other Industry"
                      />
                    </div>
                  )}

                  <div className="w-full mt-2">
                    <label htmlFor="email" className="font-bold">
                      Email
                    </label>
                    <Field
                      name="email"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="text"
                      disabled
                    />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full flex lg:flex-row flex-col gap-[5px]">
                  <div className="w-full mt-2">
                    <label htmlFor="phoneno" className="font-bold">
                      Phone No.
                    </label>
                    <Field
                      name="phoneno"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      type="text"
                      disabled
                    />
                    {errors.phoneno && touched.phoneno ? (
                      <div style={{ color: "red" }}>{errors.phoneno}</div>
                    ) : null}
                  </div>

                  <div className="w-full mt-2">
                    <label htmlFor="date" className="font-bold">
                      Date Of Birth
                    </label>
                    <div>
                      <DatePicker
                        selected={date ? new Date(date) : null}
                        onChange={(date) => setDate(date)}
                        dateFormat="dd/MM/yyyy" 
                        customInput={
                          <Field
                            name="date"
                            className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          />
                        } 
                        placeholderText="DD/MM/YYYY"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        maxDate={new Date()}
                      />
                      {!date ? (
                        <div style={{ color: "red" }}>
                          Date can not be empty
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="d-sm-flex ">
                  <div className="w-100 mr-3 mt-2">
                    <label htmlFor="profilePhoto" className="font-bold">
                      Profile Photo <span className="text-danger">*</span>
                    </label>
                    {fieldVaue == null ? (
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          changeImage(event);
                        }}
                        className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      />
                    ) : (
                      <div className="">
                        {" "}
                        <img
                          src={fieldVaue}
                          width="100px"
                          height="100px"
                          style={{ display: "block" }}
                        ></img>
                        <Button
                          onClick={handleRemoveImage}
                          type="button"
                          className="bg-forestgreen hover:bg-darkslateblue-100 mt-2"
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                  
                  <div className="w-full mt-2">
                    <label htmlFor="registered_region" className="font-bold">
                      Current Location
                    </label>
                    <Field
                      as="select"
                      className="mt-1 border border-black rounded-md block w-full h-10 px-2 text-1xl focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      name="registered_region"
                      value={registerRegion}
                      onChange={(e) => setRegisterRegion(e.target.value)}
                    >
                      <option defaultChecked disabled value="">
                        Select State
                      </option>
                      {_.map(
                        _.get(provoiderCardsArray, "states", []),
                        (stateObj) => (
                          <option value={_.get(stateObj, "state", "")}>
                            {_.get(stateObj, "state", "")}
                          </option>
                        )
                      )}
                    </Field>
                    {!registerRegion ? (
                      <div style={{ color: "red" }}>
                        Register region can not be empty
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <label htmlFor="experince" className="font-bold ">
                  Subscription :
                </label>
                <span className="label label-info">
                  {" "}
                  {_.get(provoiderCardsArray, "profile.is_subscribed", false)
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div className="mt-2">
                <label htmlFor="experince" className="font-bold ">
                  Resource Type :
                </label>
                <span className="label label-info">
                  {" "}
                  {providerPrfoile ? "Provider" : "Seeker"}
                </span>
              </div>
              <div className="mt-2 flex items-center justify-center">
                <Button
                  type="submit"
                  className="bg-forestgreen hover:bg-darkslateblue-100 text-white py-3 rounded-md mt-2"
                  style={
                    !otherIndustry || !registerRegion || !date
                      ? { pointerEvents: "none", opacity: "0.5" }
                      : {}
                  }
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  {isSubmitting ? "Please wait..." : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </div>
  );
};

export default ImageUploader;
