// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { AuthSign } from "../../../redux/slices/authSlice";
// import { modalManage } from "../../../redux/slices/authModalHandleSlice";
// import { useNavigate } from "react-router-dom";
// import VerifyPhoneOTP from "../../VerifyPhoneOTP/VerifyPhoneOTP";
// import SignupPopup from "../Sign-up/SignupPopup";
// import "./LoginPage.css";

// function LoginPage() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false); // State for password visibility
//   const [error, setError] = useState("");
//   const [previousEmail, setPreviousEmail] = useState("");
//   const [signupPopupOpen, setSignupPopupOpen] = useState(false);
//   const [showErrorPopup, setShowErrorPopup] = useState(false); // State for error popup

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const modalHandle = useSelector(
//     (state) => state.reducer.authModalHandle.modalHandle
//   );

//   // Handle login form submission
//   const handleLogin = (e) => {
//     e.preventDefault();
//     setError(""); // Reset error state on submit

//     const reqData = { email, password };
//     setPreviousEmail(email);

//     dispatch(AuthSign(reqData, navigate))
//       .unwrap()
//       .then(() => navigate("/dashboard"))
//       .catch(() => {
//         setError("Invalid email or password");
//         setShowErrorPopup(true); // Show error popup
//       });
//   };

//   // Open Forgot Password modal
//   const handleForgotPass = () => {
//     dispatch(modalManage("forgot"));
//   };

//   // Close all modals
//   const handleCloseModal = () => {
//     dispatch(modalManage("allModalClose"));
//   };

//   // Open the signup popup modal
//   const handleOpenSignupPopup = () => {
//     setSignupPopupOpen(true);
//   };

//   // Close the signup popup modal
//   const handleCloseSignupPopup = () => {
//     setSignupPopupOpen(false);
//   };

//   // Toggle password visibility
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   // Handle "OK" click in error popup
//   const handleErrorPopupClose = () => {
//     setShowErrorPopup(false);
//   };

//   const navigateToHome = () => {
//     navigate("/");
//   };

//   return (
//     <div className="login-container">
//       {!modalHandle.forgotModal && (
//         <>
//           <div className="login-left">
//             <h1>Welcome to Resolab</h1>
//             <p>Exclusive Hiring Platform For MFIs.</p>
//           </div>
//           <div className="login-right">
//             <div className="login-form">
//               <div className="login-form-border">
//                 <h2 className="text-center">LOGIN</h2>
//                 <form onSubmit={handleLogin}>
//                   <label className="login-label">Email Address</label>
//                   <input
//                     className="login-input"
//                     type="email"
//                     placeholder="Enter your email"
//                     required
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                   <label className="login-label">Password</label>
//                   <div className="password-container">
//                     <input
//                       className="login-input password-input"
//                       type={showPassword ? "text" : "password"}
//                       placeholder="Enter your password"
//                       required
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                     />
//                     <span
//                       className="password-toggle"
//                       onClick={togglePasswordVisibility}
//                       role="button"
//                       tabIndex={0}
//                     >
//                       <i
//                         className={`fa-solid ${
//                           showPassword ? "fa-eye" : "fa-eye-slash"
//                         }`}
//                       ></i>
//                     </span>
//                   </div>

//                   {error && <p className="error-message">{error}</p>}
//                   <button type="submit" className="login-button">
//                     SUBMIT
//                   </button>
//                 </form>
//                 <div className="create-account-link">
//                   <button
//                     type="button"
//                     className="forgot-password"
//                     onClick={handleForgotPass}
//                   >
//                     Forgot Password
//                   </button>
//                   <span onClick={handleOpenSignupPopup} className="link">
//                     <b>Don't have an account?</b>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//       {modalHandle.forgotModal && (
//         <div className="modal-overlay" onClick={handleCloseModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <VerifyPhoneOTP />
//           </div>
//         </div>
//       )}
//       {signupPopupOpen && (
//         <SignupPopup open={signupPopupOpen} onClose={handleCloseSignupPopup} />
//       )}
//       {showErrorPopup && (
//         <div className="error-popup">
//           <div className="error-popup-content">
//             <p>{error}</p>
//             <button onClick={handleErrorPopupClose}>Done</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default LoginPage;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthSign } from "../../../redux/slices/authSlice";
import { modalManage } from "../../../redux/slices/authModalHandleSlice";
import { useNavigate } from "react-router-dom";
import VerifyPhoneOTP from "../../VerifyPhoneOTP/VerifyPhoneOTP";
import SignupPopup from "../Sign-up/SignupPopup";
import "./LoginPage.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [previousEmail, setPreviousEmail] = useState("");
  const [signupPopupOpen, setSignupPopupOpen] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State for tracking submission

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalHandle = useSelector(
    (state) => state.reducer.authModalHandle.modalHandle
  );

  // Handle login form submission
  const handleLogin = (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true); // Start submission

    const reqData = { email, password };
    setPreviousEmail(email);

    dispatch(AuthSign(reqData, navigate))
      .unwrap()
      .then(() => {
        setIsSubmitting(false); // Stop submission
        navigate("/dashboard");
      })
      .catch(() => {
        setIsSubmitting(false); // Stop submission
        setError("Invalid email or password");
        setShowErrorPopup(true); // Show error popup
      });
  };

  // Open Forgot Password modal
  const handleForgotPass = () => {
    dispatch(modalManage("forgot"));
  };

  // Close all modals
  const handleCloseModal = () => {
    dispatch(modalManage("allModalClose"));
  };

  // Open the signup popup modal
  const handleOpenSignupPopup = () => {
    setSignupPopupOpen(true);
  };

  // Close the signup popup modal
  const handleCloseSignupPopup = () => {
    setSignupPopupOpen(false);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle "OK" click in error popup
  const handleErrorPopupClose = () => {
    setShowErrorPopup(false);
    setIsSubmitting(false); // Stop spinner and show normal submit button
  };

  return (
    <div className="login-container">
      {!modalHandle.forgotModal && (
        <>
          <div className="login-left">
            <h1>Welcome to Resolab</h1>
            <p>Exclusive Hiring Platform For MFIs.</p>
          </div>
          <div className="login-right">
            <div className="login-form">
              <div className="login-form-border">
                <h2 className="text-center">LOGIN</h2>
                <form onSubmit={handleLogin}>
                  <label className="login-label">Email Address</label>
                  <input
                    className="login-input"
                    type="email"
                    placeholder="Enter your email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="login-label">Password</label>
                  <div className="password-container">
                    <input
                      className="login-input password-input"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                      role="button"
                      tabIndex={0}
                    >
                      <i
                        className={`fa-solid ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      ></i>
                    </span>
                  </div>

                  {error && <p className="error-message">{error}</p>}
                  <button
                    type="submit"
                    className="login-button"
                    disabled={isSubmitting}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isSubmitting ? (
                      <>
                        <i
                          className="fa-solid fa-spinner fa-spin"
                          style={{ marginRight: "5px" }}
                        ></i>
                        <span>Loading...</span>
                      </>
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </form>
                <div className="create-account-link">
                  <button
                    type="button"
                    className="forgot-password"
                    onClick={handleForgotPass}
                  >
                    Forgot Password
                  </button>
                  <span onClick={handleOpenSignupPopup} className="link">
                    <b>Don't have an account?</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {modalHandle.forgotModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <VerifyPhoneOTP />
          </div>
        </div>
      )}
      {signupPopupOpen && (
        <SignupPopup open={signupPopupOpen} onClose={handleCloseSignupPopup} />
      )}
      {showErrorPopup && (
        <div className="error-popup">
          <div className="error-popup-content">
            <p>{error}</p>
            <button onClick={handleErrorPopupClose}>Done</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
