import { useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import axios from "axios"; // Import axios for making HTTP requests
import { setCookie } from "../../../config/cookies"; // Import cookie utility
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import Navbar2 from "../../../components/navbarhome";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector for Redux
import { getDistrict } from "../../../redux/slices/providerCardSlice"; // Import your district action
import _ from "lodash"; // Import lodash for utility functions

const Partner = () => {
  const [stateVal, setStateVal] = useState("");
  const [districtVal, setDistrictVal] = useState("");
  const [education, setEducation] = useState("");
  const [hasVehicle, setHasVehicle] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [nativeDistrict, setNativeDistrict] = useState("");
  const [totalEarningMembers, setTotalEarningMembers] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch(); // Initialize useDispatch
  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  ); // Get provider cards from Redux

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data to be sent to the API
    const formData = {
      name: e.target.elements.name.value,
      phone_number: e.target.elements.phone_number.value,
      email: e.target.elements.email.value,
      date_of_birth: e.target.elements.date_of_birth.value,
      state: stateVal,
      district: districtVal,
      native_of_district: nativeDistrict === "Yes",
      education,
      has_vehicle: hasVehicle === "Yes",
      type_of_vehicle: vehicleType,
      earning_members_in_family: totalEarningMembers,
      monthly_family_income: monthlyIncome,
    };

    try {
      const response = await axios.post(
        "https://backend.resolabindia.com/partner/check_partner_eligibility/",
        formData
      );

      // Store response data in cookies
      const responseData = {
        id: response.data.id, // Assuming your response contains an ID
        name: formData.name,
        phone_number: formData.phone_number,
        email: formData.email,
        date_of_birth: formData.date_of_birth,
        state: formData.state,
        district: formData.district,
        native_of_district: response.data.native_of_district,
        education: formData.education,
        has_vehicle: response.data.has_vehicle,
        type_of_vehicle: response.data.type_of_vehicle,
        earning_members_in_family: response.data.earning_members_in_family,
        monthly_family_income: response.data.monthly_family_income,
        points: response.data.points,
        eligible: response.data.eligible,
      };

      setCookie("partnerFormData", JSON.stringify(responseData), 7); // Store for 7 days

      if (response.data.eligible) {
        Swal.fire({
          icon: "success",
          title: "Congratulations 🎉",
          text: `You are eligible for Partner. Points: ${response.data.points}`,
        }).then(() => {
          // Redirect to the PartnerPage on "OK" click
          navigate("/partnerpage");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Not Eligible",
          text: `You are not eligible. Points: ${response.data.points}`,
        });
      }
    } catch (error) {
      // Check for specific error responses related to phone_number and email
      const errorResponse = error.response?.data || {};

      if (errorResponse.phone_number && errorResponse.email) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "phone number and email address is already exist.",
        });
      } else if (errorResponse.phone_number) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Phone number is already exists.",
        });
      } else if (errorResponse.email) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Email address is already exists.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while submitting the form.",
        });
      }
    }
  };

  const handleDistrictChange = (state) => {
    setStateVal(state);
    dispatch(getDistrict(state)); // Fetch districts based on selected state
  };

  return (
    <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px] text-center">
        <div className="w-full rounded-11xl overflow-hidden bg-[#eaeaea]">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
            PARTNER
          </h1>

        <div className="min-h-screen bg-gray-100 flex flex-col items-center">
          <div className="container mx-auto px-6 py-12 flex flex-col lg:flex-row gap-8">
            {/* Left Box */}
            <div className="pb-[10px] mt-[0px] font-dosis text-darkslateblue-100 flex flex-col justify-center gap-[5px] z-[2] text-light">
                <h3 className="m-0 text-heading text-center font-bold z-[2] mq800:text-32xl mq450:text-15xl">
                  Join Us
                </h3>
                <h4 className="m-0 text-32xl font-bold z-[2] mq800:text-32xl mq450:text-15xl">
                  Become a District Resource Partner (DRP)
                </h4>
                <h4 className="m-0 text-10xl text-end text-yellow-400 font-bold z-[2]  mq800:text-3xl mq450:text-3xl">
                  Start Your Own Recruitment Business
                </h4>
              </div>

            {/* Right Box */}
            <div className="lg:w-1/2 bg-transperent shadow-md rounded-md">
              <form
                className="w-full sm:w-5/5 md:w-2/3 bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-md"
                onSubmit={handleSubmit}
              >
                <Typography
                  variant="h4"
                  className="mb-6 text-darkslateblue-100 text-2xl text-center sm:text-3xl lg:text-4xl"
                >
                  Check Your Eligibility
                </Typography>

                <div className="flex mb-4 gap-4">
                  <TextField
                    name="name"
                    fullWidth
                    label="Your Name"
                    variant="outlined"
                    required
                  />
                  <TextField
                    name="date_of_birth"
                    fullWidth
                    label="Date of Birth"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    required
                  />
                </div>

                <div className="flex mb-4 gap-4">
                  <TextField
                    name="phone_number"
                    fullWidth
                    label="Phone No"
                    type="tel"
                    variant="outlined"
                    required
                  />
                  <TextField
                    name="email"
                    fullWidth
                    label="Email ID"
                    type="email"
                    variant="outlined"
                    required
                  />
                </div>

                <div className="mb-4 text-start">
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Education</InputLabel>
                    <Select
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      label="Education"
                    >
                      <MenuItem value="12TH">12th</MenuItem>
                      <MenuItem value="GR">Graduate</MenuItem>
                      <MenuItem value="PG">Post Graduate</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="flex mb-4 gap-4 text-start">
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>State</InputLabel>
                    <Select
                      value={stateVal}
                      onChange={(e) => handleDistrictChange(e.target.value)} // Pass the selected state ID
                      label="State"
                    >
                      {_.map(
                        _.get(provoiderCardsArray, "states", []),
                        (stateObj) => (
                          <MenuItem
                            key={_.get(stateObj, "id", "")}
                            value={_.get(stateObj, "id", "")}
                          >
                            {_.get(stateObj, "state", "")}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>District</InputLabel>
                    <Select
                      value={districtVal} // Set the selected district value
                      onChange={(e) => setDistrictVal(e.target.value)} // Update district state
                      label="District"
                    >
                      {_.map(
                        _.get(provoiderCardsArray, "districts", []),
                        (districtObj) => (
                          <MenuItem
                            key={_.get(districtObj, "id", "")}
                            value={_.get(districtObj, "id", "")}
                          >
                            {_.get(districtObj, "district", "")}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>

                <div className="mb-4">
                  <Typography variant="h6">
                    Are you a native of this district?
                  </Typography>
                  <RadioGroup
                    value={nativeDistrict}
                    onChange={(e) => setNativeDistrict(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </div>

                <div className="mb-4 text-start">
                  <Typography variant="h6">Do you have a vehicle?</Typography>
                  <RadioGroup
                    value={hasVehicle}
                    onChange={(e) => setHasVehicle(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
                {hasVehicle === "Yes" && (
                  <div className="mb-4 text-start">
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel>Type of Vehicle</InputLabel>
                      <Select
                        value={vehicleType}
                        onChange={(e) => setVehicleType(e.target.value)}
                        label="Type of Vehicle"
                      >
                        <MenuItem value="2W">Two-Wheeler</MenuItem>
                        <MenuItem value="CAR">Four-Wheeler</MenuItem>
                        <MenuItem value="BOTH">Both</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="mb-4 text-start">
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Total Earning Members in Family</InputLabel>
                    <Select
                      value={totalEarningMembers}
                      onChange={(e) => setTotalEarningMembers(e.target.value)}
                      label="Total Earning Members in Family"
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>

                      <MenuItem value="MORE_THAN_2">More than 2</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="mb-4 text-start">
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Monthly Family Income</InputLabel>
                    <Select
                      value={monthlyIncome}
                      onChange={(e) => setMonthlyIncome(e.target.value)}
                      label="Monthly Family Income"
                    >
                      <MenuItem value="LESS_THAN_25000">
                        Less Then ₹25000
                      </MenuItem>
                      <MenuItem value="BETWEEN_25000_50000">
                        ₹25000 - ₹50000
                      </MenuItem>
                      <MenuItem value="MORE_THAN_50000">
                        More Then ₹50000
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
