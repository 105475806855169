import _ from "lodash";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { GoShield } from "react-icons/go";
import PageLoader from "../../../layout/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import axiosInstance from "../../../config/axios";
import "./PaymentPage.css";
import { getCookie } from "../../../config/cookies";

const PaymentPage = () => {
  const [amountType, setAmountType] = useState("Fixed Amount");
  const [amount, setAmount] = useState("");
  const [subscriptionAmount, setSubscriptionAmount] = useState(3650);
  const [error, setError] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [gstError, setGstError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch provider and seeker status from cookies
  const isProvider = getCookie("is_provider") === "true";
  const isSeeker = getCookie("is_seeker") === "true";

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const user = useSelector((state) => ({
    fullname: `${state.reducer.provoiderCard.profile.first_name || ""} ${
      state.reducer.provoiderCard.profile.last_name || ""
    }`,
    email: state.reducer.provoiderCard.profile.email || "",
    phone: state.reducer.provoiderCard.profile.phone_number || "",
    homeState: state.reducer.provoiderCard.profile.home_state || "",
  }));

  console.log(user);
  const numericAmount = parseFloat(amount) || 0;

  let cgst = 0,
    sgst = 0,
    igst = 0;
  const isGstStartsWith24 = gstNumber.startsWith("24");
  const isHomeStateGujarat = user.homeState.toLowerCase() === "gujarat";

  if (numericAmount >= 99) {
    if (isProvider && isHomeStateGujarat) {
      // Show CGST and SGST for providers with home state Gujarat
      cgst = numericAmount * 0.09;
      sgst = numericAmount * 0.09;
    } else if (isGstStartsWith24) {
      // Show CGST and SGST for GST numbers starting with 24
      cgst = numericAmount * 0.09;
      sgst = numericAmount * 0.09;
    } else {
      // Show IGST for others
      igst = numericAmount * 0.18;
    }
  }

  const totalPayable =
    numericAmount >= 99 ? Math.round(numericAmount + cgst + sgst + igst) : 0;

  useEffect(() => {
    const storedAmountType = localStorage.getItem("amountType");
    const storedAmount = localStorage.getItem("amount");

    if (storedAmountType) setAmountType(storedAmountType);
    if (storedAmount) setAmount(storedAmount);

    const { amount: subscriptionAmountFromLocation } = location.state || {};
    if (subscriptionAmountFromLocation) {
      setSubscriptionAmount(subscriptionAmountFromLocation);
      setAmount(subscriptionAmountFromLocation);
    }
  }, [location.state]);

  useEffect(() => {
    localStorage.setItem("amountType", amountType);
    localStorage.setItem("amount", amount);
  }, [amountType, amount]);

  const handleAmountTypeChange = (event) => {
    const selectedType = event.target.value;
    setAmountType(selectedType);
    if (selectedType === "Dynamic Amount") {
      setAmount("");
      setError("");
      setTotalAmount(0);
    } else {
      setAmount(subscriptionAmount);
      setError("");
      setTotalAmount(subscriptionAmount + subscriptionAmount * 0.18);
    }
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    if (parseFloat(newAmount) < 99) {
      setError("Amount must be at least ₹99.");
      setTotalAmount(0);
    } else {
      setError("");
      const roundedTotalAmount = Math.round(
        parseFloat(newAmount) + parseFloat(newAmount) * 0.18
      );
      setTotalAmount(roundedTotalAmount);
    }
  };

  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleGstNumberChange = (e) => {
    const value = e.target.value.toUpperCase(); // Ensure input is in uppercase
    setGstNumber(value);

    // Clear the error message when the user starts typing
    if (gstError) {
      setGstError("");
    }

    // Validate if the GST number is 15 characters long
    if (value.length > 15) {
      setGstNumber(value.slice(0, 15)); // Limit the GST number to 15 characters
    }
  };

  const createOrder = async (amount) => {
    const apiUrl = "/subscription/create-custom-payment/";
    const totalAmountWithGst = Math.round(parseFloat(amount) * 1.18);

    const requestBody = {
      amount: totalAmountWithGst,
      gst_number: gstNumber,
    };

    try {
      const response = await axiosInstance.post(apiUrl, requestBody);
      const orderId = response.data?.order_id;
      const orderAmount = response.data?.amount;

      if (!orderId) {
        throw new Error("Order ID is missing in the response.");
      }

      return { id: orderId, amount: orderAmount || totalAmountWithGst * 100 };
    } catch (error) {
      console.error("Error during order creation:", error);
      throw new Error("Failed to create order");
    }
  };

  const verifyPayment = async (paymentId, orderId, signature) => {
    const apiUrl = "/subscription/verify-custom-payment/";

    const payload = {
      razorpay_payment_id: paymentId,
      razorpay_order_id: orderId,
      razorpay_signature: signature,
    };

    try {
      const response = await axiosInstance.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      console.error(
        "Verify Payment Error:",
        error.response ? error.response.data : error.message
      );
      throw new Error("Failed to verify payment");
    }
  };

  const handlePayment = async () => {
    if (
      amountType === "Dynamic Amount" &&
      (isNaN(numericAmount) || numericAmount < 99)
    ) {
      setError("Amount must be at least ₹99.");
      return;
    }

    if (isSeeker && !gstNumber) {
      setGstError("Please fill your GST number.");
      return;
    } else if (isSeeker && gstNumber.length !== 15) {
      setGstError("Wrong GST number. It should be 15 characters long.");
      return;
    } else {
      setGstError(""); // Clear any previous GST error if present
    }

    setPaymentProcessing(true);

    try {
      const razorpayLoaded = await loadRazorpayScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!razorpayLoaded) {
        throw new Error("Razorpay script failed to load. Please try again.");
      }

      const orderData = await createOrder(amount);
      const { id, amount: orderAmount } = orderData;

      if (!id) {
        throw new Error("Order ID is missing.");
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: Math.round(totalPayable * 100),
        currency: "INR",
        name: "",
        description: "Resolab Subscription",
        order_id: id,
        handler: async function (response) {
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;
          await verifyPayment(
            razorpay_payment_id,
            razorpay_order_id,
            razorpay_signature
          );
          Swal.fire({
            icon: "success",
            title: "Payment Successful",
            text: "Your payment was completed successfully.",
          });
          navigate("/success");
        },
        prefill: {
          name: user.fullname,
          email: user.email,
          contact: user.phone,
        },
        notes: {
          gst_number: gstNumber, // Add GST number to transaction notes
        },
        theme: {
          color: "#3399cc",
        },
      };      

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: error.message,
      });
      console.error("Payment Error:", error);
    } finally {
      setPaymentProcessing(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="payment-page-container">
      <div className="payment-section">
        <div className="header">
          <h3>Payment Checkout</h3>
        </div>
        <div className="title">
          Payment Details
          <div className="title-underline"></div>
        </div>

        <div className="form-section">
          <div className="form-groups">
            <label className="form-label">Name</label>
            <input className="form-controls" value={user.fullname} disabled />
          </div>

          <div className="form-groups">
            <label className="form-label">Email</label>
            <input className="form-controls" value={user.email} disabled />
          </div>
          <div className="form-groups">
            <label className="form-label">Phone Number</label>
            <input className="form-controls" value={user.phone} disabled />
          </div>
          <div className="form-groups">
            {isSeeker && !isProvider && (
              <div className="gst-section">
                <label className="form-label">GST Number:</label>
                <input
                  className="form-controls"
                  type="text"
                  value={gstNumber}
                  onChange={handleGstNumberChange}
                  placeholder="Enter GST Number"
                />
                {gstError && (
                  <div className="error-message ms-3">{gstError}</div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="payment-options">
          <div className="title">
            Payment Options<div className="title-underline"></div>
          </div>
          <div className="form-checks">
            <input
              type="radio"
              className="form-check-inputs"
              name="amountType"
              value="Fixed Amount"
              checked={amountType === "Fixed Amount"}
              onChange={handleAmountTypeChange}
            />
            <label className="form-check-label">
              Yearly Subscription ₹{subscriptionAmount}/-
            </label>
          </div>
          <div className="form-checks">
            <input
              type="radio"
              className="form-check-inputs"
              name="amountType"
              value="Dynamic Amount"
              checked={amountType === "Dynamic Amount"}
              onChange={handleAmountTypeChange}
            />
            <label className="form-check-label">Other Payment</label>
          </div>

          {amountType === "Dynamic Amount" && (
            <div className="custom-amount">
              <input
                type="number"
                className="form-controls"
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter Amount"
              />
              {error && <div className="error-message ms-3">{error}</div>}
            </div>
          )}
        </div>
        <div className="payment-footer">
          <img
            id="fin-logo"
            alt="pay-methods"
            src="https://cdn.razorpay.com/static/assets/pay_methods_branding.png"
            className="fin-logo"
          />
          <div className="disclaimer">
            <p>
              <GoShield className="icon" />
              All transactions are secure and encrypted. We do not share your
              payment information.
            </p>
          </div>
        </div>
      </div>

      <div className="pricecard">
        <div className="pricecard-header">
          <h4>Amount Details</h4>
        </div>
        <div className="pricecard-body">
          <div className="detail-row">
            <p className="label">Amount</p>
            <p className="value">₹{parseFloat(numericAmount).toFixed(2)}</p>
          </div>
          {isGstStartsWith24 || (isProvider && isHomeStateGujarat) ? (
            <>
              <div className="detail-row">
                <p className="label">CGST (9%)</p>
                <p className="value">₹{cgst.toFixed(2)}</p>
              </div>
              <div className="detail-row">
                <p className="label">SGST (9%)</p>
                <p className="value">₹{sgst.toFixed(2)}</p>
              </div>
            </>
          ) : (
            <div className="detail-row">
              <p className="label">IGST (18%)</p>
              <p className="value">₹{igst.toFixed(2)}</p>
            </div>
          )}
        </div>
        <div className="total-row">
          <p className="label">Total Payable</p>
          <p className="value">₹{totalPayable}</p>
        </div>
        <div className="pricecard-footer">
          <button
            className="pay-button"
            onClick={handlePayment}
            disabled={paymentProcessing}
          >
            {paymentProcessing ? "Processing..." : `Pay ₹${totalPayable}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
