import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Verification = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px]  text-center">
        <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
          VERIFICATION
          </h1>

          <section className="relative py-0 box-border w-full text-21xl text-black  text-center inline-block">
            <div className="flex flex-col items-center justify-center gap-[15px] text-3xl ">
              <div className="lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-5 px-10 sm:px-0 sm:py-5 bg-whitesmoke rounded-11xl z-[1]">
                <div className="flex flex-col justify-center ">
                  <div className="flex flex-col items-center justify-center">
                    
                    <div className="text-left text-[1.5rem] sm:text-[1rem] w-[70%] mx-auto">
                      <p>
                        Background verification play an important role , it help to validate information provided by the employee viz. Identity, Address, Education, Employment history, Legal /criminal history.
                      </p>
                      <p>We are designing customize solutions for MFIs to validate the authenticity and accuracy of an individual.
                      </p>
                    </div>
                  </div>
                  
                  <div className=" flex flex-col lg:flex-row items-center justify-evenly ">
                    
                    
                  </div>
                  
                </div>
              </div>
            </div>
            
          </section>
        </div>
      </div>
    </>
  );
};

export default Verification;
