import React from "react";

const Advisory = () => {
  const openGmailCompose = () => {
    const receiverEmail = 'connect@resolabindia.com';
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
    window.open(mailtoLink);
  };
  return (
    <>
      <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px]  text-center">
        <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
          ADVISORY SERVICES
          </h1>

          <section className="relative py-0 box-border w-full text-21xl text-darkslateblue-100 text-center inline-block">
            <div className="flex flex-col items-center pb-[0px] justify-center gap-[15px] text-3xl ">
              <div className="lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-10 px-36 sm:px-8 sm:py-5 bg-whitesmoke rounded-11xl z-[1]">
                <div className="text-left text-[1.5rem] sm:text-[1rem] text-black">
                  <p>
                    Workforce planning, Employee engagement, Back ground Verification  skill gap analysis & skilling of field team comes under scope of advisory services.
                  </p>
                  <p>Apart from MFIs we also provide staffing solutions to company working for skilling development space focusing on skilling to rural poor.
                  </p>
                  <p>
                    More information mail to{" "}
                    <a href="mailto:connect@resolabindia.com" onClick={openGmailCompose} className="text-blue-500 underline">
                      connect@resolabindia.com
                    </a>
                  </p>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Advisory;
