import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getProviderProfileDetails } from "../../redux/slices/providerCardSlice";
import SectionLoader from "../../layout/SectionLoader/SectionLoader";
import "../Price/Price.css";
import Navbar2 from "../../components/navbarhome";
import Footer from "../../components/footer"; // Assuming Footer is the footer component you're using
import Company from "./Company/Company";
import Team from "./Team/Team";
import Career from "./Career/Career";
import Partner from "./Partner/Partner";

const AboutUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("");  // Default section is "hiring"

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const providerCardsArray = useSelector((state) =>
    _.get(state, "reducer.providerCard", {})
  );

  useEffect(() => {
    if (_.get(userDetail, "user.id", "")) {
      dispatch(getProviderProfileDetails(_.get(userDetail, "user.id", "")));
    }
  }, [dispatch, userDetail]);

  // Update the current section when the component is mounted or the location state changes
  useEffect(() => {
    if (location.state?.section) {
      setCurrentPage(location.state.section);  // Set the section passed via state
    } else {
      const params = new URLSearchParams(location.search);
      const section = params.get("section");
      if (section) {
        setCurrentPage(section);  // Set the section from the URL query params
      }
    }
  }, [location.state, location.search]);

  const handlePageChange = (page) => {
    setLoading(true);
    setTimeout(() => {
      setCurrentPage(page);
      navigate(`/aboutus`, { state: { section: page } });  // Pass section via location.state
      setLoading(false);
    }, 1000);
  };

  const renderContent = () => {
    if (loading) {
      return <SectionLoader />;
    }
    switch (currentPage) {
      case "company":
        return <Company />;
      case "team":
        return <Team />;
      case "career":
        return <Career />;
      case "partner":
        return <Partner />;
      default:
        return null;  // No content if no valid section
    }
  };

  return (
    <>
      <Navbar2 />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[40px]">
        <section className="self-stretch bg-darkslateblue-100 flex flex-col items-center justify-center pt-0 lg:px-5 box-border text-center text-45xl text-white font-dosis lg:pt-24">
        <div className="w-full px-[45px] box-border">
      <div className="flex flex-col items-center justify-center relative max-w-full gap-[40px]">
        {/* Add the image below */}
        <img
          className="lg:h-[350px] w-[full] absolute bottom-[0px] left-[-50px] z-[1] h-[0px]"
          loading="lazy"
          alt=""
          src="/image-1@2x.png"
        />
              <div className="lg:py-[80px] py-[30px] flex flex-col items-center justify-center gap-[35px] z-[2]">
                <h3 className="m-0 text-heading font-bold z-[2]">About Us</h3>
                <div className="mt-1 button-container">
                  <Button
                    className={`nav-button mx-2 w-40 h-12 text-white ${currentPage === "company" ? "active" : ""}`}
                    onClick={() => handlePageChange("company")}
                  >
                    Company
                  </Button>
                  <Button
                    className={`nav-button mx-2 w-40 h-12 text-white ${currentPage === "team" ? "active" : ""}`}
                    onClick={() => handlePageChange("team")}
                  >
                    Team
                  </Button>
                  <Button
                    className={`nav-button mx-2 w-40 h-12 text-white ${currentPage === "career" ? "active" : ""}`}
                    onClick={() => handlePageChange("career")}
                  >
                    Career
                  </Button>
                  <Button
                    className={`nav-button mx-2 w-40 h-12 text-white ${currentPage === "partner" ? "active" : ""}`}
                    onClick={() => handlePageChange("partner")}
                  >
                    Partner
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {renderContent()}

        {/* Footer Section with buttons to navigate to specific sections */}
        <Footer>
          <Button
            onClick={() => handlePageChange("company")}
            className={`footer-button ${currentPage === "company" ? "active" : ""}`}
          >
            Company
          </Button>
          <Button
            onClick={() => handlePageChange("team")}
            className={`footer-button ${currentPage === "team" ? "active" : ""}`}
          >
            Team
          </Button>
          <Button
            onClick={() => handlePageChange("career")}
            className={`footer-button ${currentPage === "career" ? "active" : ""}`}
          >
            Career
          </Button>
          <Button
            onClick={() => handlePageChange("Partner")}
            className={`footer-button ${currentPage === "Partner" ? "active" : ""}`}
          >
            Partner
          </Button>
        </Footer>
      </div>
    </>
  );
};

export default AboutUs;
