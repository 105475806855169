// import React from "react";

// const Team = () => {
//   return (
//     <>
//       <div className="lg:w-4/4 w-[90%] relative bg-white h-auto overflow-hidden text-5xl text-black flex flex-col gap-[40px] text-center">
//         <div className="w-full rounded-11xl overflow-hidden bg-whitesmoke">
//           <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
//             OUR TEAM
//           </h1>

//           <section className="relative py-0 box-border w-[full]  text-21xl font-semibold text-darkslateblue-100 text-center inline-block ">
//             <div className="flex items-center justify-center pb-20">
//               <div className="flex-wrap items-start justify-center font-dosis max-w-full w-[80%] ">
//                 We are a team of passionate development professionals committed
//                 to improving the resource landscape of the INDIAN microfinance
//                 industry.
//               </div>
//             </div>
//             <div className="flex items-center justify-center ">
//               <div className="flex lg:flex-row flex-col p-10 lg:gap-[40px]  bg-whitesmoke rounded-xl lg:h-[300px] w-[90%]">
//                 <img
//                   className="rounded-xl h-[360px] lg:mt-[20px] w-auto transform  -translate-y-1/4"
//                   alt="Founder"
//                   src="/paras.png"
//                 />
//                 <div className="flex flex-col items-start justify-start gap-[30px] text-black sm:mt-[-30px]">
//                   <b className="relative lg:text-45xl font-dosis">
//                     Note from Our Founder
//                   </b>
//                   <div className="flex flex-col items-start justify-start gap-[15px] text-3xl font-raleway">
//                     <div className=" relative leading-[150%] text-left font-medium inline-block">
//                       Resolab is a talent marketplace for the microfinance
//                       industry, connecting leading organizations with the best
//                       resources. We believe that the right talent can transform
//                       the microfinance industry, and we are here to make that
//                       happen.
//                     </div>
//                     <b className=" leading-[150%] z-[1] inline-block">
//                       - Paras Kumar
//                     </b>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <section className="relative self-stretch flex flex-row items-center justify-center mt-20 box-border max-w-full text-13xl text-black font-raleway">
//               <div className="h-full w-full flex flex-row flex-wrap items-center justify-center relative gap-4 max-w-full">
//                 <div className=" flex flex-col items-center">
//                   <img
//                     className="object-top rounded-xl h-[350px] w-[300px] object-cover transform"
//                     alt=""
//                     src="/abhinav.png"
//                   />
//                   <div className="flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px] ">
//                     <b className="relative font-dosis text-10xl pt-5">
//                       Abhinav Mishra
//                     </b>
//                     <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
//                       <div className=" relative leading-[150%] font-medium inline-block">
//                         Abhinav has worked with mid tier to large scale multi
//                         national companies and he helped growth of some of the
//                         largest organization in Europe and USA. In his last
//                         stint he was managing a team of professionals in India
//                         delivering cutting edge technology solutions to DP DHL.
//                       </div>
//                       <b className=" relative leading-[150%] inline-block">
//                         Director
//                       </b>
//                     </div>
//                   </div>
//                 </div>
//                 <div className=" flex flex-col items-center">
//                   <img
//                     className="rounded-xl h-[350px] w-[300px] object-top object-cover transform "
//                     alt=""
//                     src="/rupal.png"
//                   />
//                   <div className=" flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px]">
//                     <b className="relative font-dosis text-10xl pt-5">
//                       Rupal Shah
//                     </b>
//                     <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
//                       <div className=" relative leading-[150%] font-medium inline-block">
//                         A passionate academician with two decades of teaching
//                         experience ,shaping the careers of more than 3000
//                         students in the field of Information Technology . She
//                         has been a subject matter expert in Data Structure,
//                         Operating Systems & variety of Programming language .
//                       </div>
//                       <b className=" relative leading-[150%] inline-block">
//                         Director<br></br> (Learning & Development)
//                       </b>
//                     </div>
//                   </div>
//                 </div>
//                 <div className=" flex flex-col items-center">
//                   <img
//                     className="object-top rounded-xl h-[350px] w-[300px] object-cover transform "
//                     alt=""
//                     src="/urvi.png"
//                   />
//                   <div className="flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px]">
//                     <b className="relative font-dosis text-10xl pt-5">
//                       Urvi Gadhvi
//                     </b>
//                     <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
//                       <div className=" relative leading-[150%] font-medium inline-block">
//                         Seasoned Sales Professional with more than 12 years of
//                         experience in BFSI industries with large public and
//                         private banks viz. Bank of India, Bank Of Baroda, ICICI
//                         Bank & HDFC Bank . She was into distribution , Training
//                         and channel development of financial products.
//                       </div>
//                       <b className=" relative leading-[150%] inline-block">
//                         Operation Manager
//                       </b>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </section>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Team;
import React from "react";
import "./Team.css";
import ParasImage from "../../../asset/images/paras.png";
import AbhinavImage from "../../../asset/images/Abhinav 2.png";
import RupalImage from "../../../asset/images/rupal.png";
import UrviImage from "../../../asset/images/urvi.png";
import chandrikaimage from "../../../asset/images/Chandrikaben.jpg";
import jagrutiimage from "../../../asset/images/Jagruti.png";
import hardikimage from "../../../asset/images/Hardik Solanki.png";
import arpitimage from "../../../asset/images/Arpit.png";

// TeamMember component to display individual team member details
const TeamMember = ({ name, designation, description, image }) => {
  return (
    <div className="flex flex-col lg:flex-row w-[100%] gap-4 bg-whitesmoke p-7 rounded-lg shadow-md items-center justify-center">
      {/* Image */}
      <div className="flex-shrink-0 w-40 h-40 sm:w-70 sm:h-52 lg:w-48 lg:h-60 rounded-lg overflow-hidden flex items-center justify-center">
        <img src={image} alt={name} className="w-full h-full object-cover" />
      </div>

      {/* Content */}
      <div className="flex flex-col justify-center text-left">
        <h2 className="text-xl lg:text-2xl font-bold mb-0">{name}</h2>
        <h4 className="text-md lg:text-base text-gray-600 mb-2 mt-0">
          {designation}
        </h4>
        <p className="text-sm lg:text-xl text-gray-700">{description}</p>
      </div>
    </div>
  );
};

// Team component
const Team = () => {
  return (
    <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px]  text-center">
      <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
          OUR TEAM
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 p-7 lg:p-7 p-2">
          {/* Team Members */}
          <TeamMember
            name="Paras Kumar"
            designation="Founder & Managing Director"
            description="Rural Development professional having more than 18 years of experience and expertise in microfinance, skilling, and product development focused on the bottom of the pyramid. He has worked with SEWA, ICICI, Bharti Axa, NIIT, Centum Learning along with exposure to state rural livelihood missions."
            image={ParasImage}
          />
          <TeamMember
            name="Abhinav Mishra"
            designation="Director"
            description="Abhinav has worked with mid-tier to large-scale multinational companies, helping the growth of some of the largest organizations in Europe and the USA. In his last stint, he managed a team in India delivering cutting-edge technology solutions to DP DHL."
            image={AbhinavImage}
          />
          <TeamMember
            name="Rupal Shah"
            designation="Director (Learning & Development)"
            description="A passionate academician with two decades of teaching experience, shaping the careers of more than 3000 students in the field of Information Technology. She has been a subject matter expert in Data Structure, Operating Systems, and various programming languages."
            image={RupalImage}
          />
          <TeamMember
            name="Urvi Gadhvi"
            designation="Opreation"
            description="Seasoned Sales Professional with more than 12 years of experience in BFSI industries with large public and private banks viz. Bank of India, Bank Of Baroda, ICICI Bank & HDFC Bank. She manages operations to ensure that every aspect of our business runs smoothly."
            image={UrviImage}
          />
          <TeamMember
            name="Jagruti Parmar"
            designation="Opreation"
            description="She has more than 3 years of experience in handling relationships with end-users. At RESOLAB, she interacts with users, provides information, solves problems, and ensures user satisfaction. She handles Gujarat, Bihar, Jharkhand, and Rajasthan."
            image={jagrutiimage}
          />
          <TeamMember
            name="Chandrika Makwana"
            designation="Opreation"
            description="With more than 7 years of experience in customer service and sales at Azure, WEC, and SBI, she now interacts with users, provides information, solves problems, and ensures satisfaction. She handles Gujarat, Uttar Pradesh, Chhattisgarh, and Madhya Pradesh."
            image={chandrikaimage}
          />
          <TeamMember
            name="Hardik Solanki"
            designation="Technology"
            description="He is a Software Engineer specializing in HTML, JavaScript, and React.js, focused on developing responsive applications, ensuring seamless performance, and providing reliable support to enhance user experiences through innovative solutions."
            image={hardikimage}
          />
          <TeamMember
            name="Arpit Rathod"
            designation="Technology"
            description="
Software Engineer, specializing in building robust solutions with expertise in ReactJS, HTML, CSS, Python, JavaScript, Django, C, and C++. He is managing tech development and providing support to ensure a seamless and enhanced experience for our users."
            image={arpitimage}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
