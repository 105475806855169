import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { GoShield } from "react-icons/go";
import PageLoader from "../../../layout/PageLoader/PageLoader";
import "../PaymentPageComponent/PaymentPage.css";
import axiosInstance from "../../../config/axios";

const PartnerPayment = () => {
  const [subscriptionAmount] = useState(25000); // Fixed subscription amount for testing
  const [gst, setGst] = useState(subscriptionAmount * 0.18); // GST Calculation
  const [totalPayable, setTotalPayable] = useState(subscriptionAmount + gst); // Total payable amount with GST
  const [loading, setLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('yearly'); // Default radio button selection
  const [userData, setUserData] = useState({ name: '', email: '', phone_number: '' });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const createOrder = async () => {
    const apiUrl = "/subscription/guest-create-payment/";

    try {
      const response = await axiosInstance.post(apiUrl, { amount: totalPayable });
      const orderId = response.data?.id || response.id;

      if (!orderId) {
        console.error("Error: Order ID is missing in the response.", response.data);
        throw new Error("Order ID is missing in the response. Please check the API.");
      }

      return { id: orderId, amount: totalPayable * 100 };
    } catch (error) {
      console.error("Order creation error:", error.response ? error.response.data : error.message);
      Swal.fire({
        icon: "error",
        title: "Order Creation Failed",
        text: error.response?.data?.message || "Failed to create order. Please try again later.",
      });
      throw new Error("Failed to create order");
    }
  };

  const verifyPayment = async (paymentId, orderId, signature) => {
    const apiUrl = "/subscription/verify-partner-payment/";

    const payload = {
      razorpay_payment_id: paymentId,
      razorpay_order_id: orderId,
      razorpay_signature: signature,
    };

    try {
      const response = await axiosInstance.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      console.error(
        "Verify Payment Error:",
        error.response ? error.response.data : error.message
      );
      throw new Error("Failed to verify payment");
    }
  };

  const handlePayment = async () => {
    setPaymentProcessing(true);
    try {
      const razorpayLoaded = await loadRazorpayScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!razorpayLoaded) throw new Error("Razorpay SDK failed to load");

      const orderData = await createOrder();
      const { id } = orderData;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalPayable * 100,
        currency: "INR",
        name: "Resolab",
        description: "Resolab Subscription",
        order_id: id,
        handler: async (response) => {
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
          try {
            await verifyPayment(razorpay_payment_id, razorpay_order_id, razorpay_signature);

            Swal.fire({
              icon: "success",
              title: "Payment Successful",
            });

            navigate("/home");
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Payment Verification Failed",
              text: error.message,
            });
          }
        },
        prefill: {
          name: userData.name,
          email: userData.email,
          contact: userData.phone_number,
        },
        theme: { color: "#3399cc" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: error.message,
      });
    } finally {
      setPaymentProcessing(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="payment-page-container">
      <div className="payment-section">
        <div className="header">
          <h3>Payment Checkout</h3>
        </div>
        <div className="title">
          Payment Details
          <div className="title-underline"></div>
        </div>
        <div className="form-section">
          <div className="form-groups">
            <label className="form-label">Name</label>
            <input
              className="form-controls"
              name="name"
              placeholder="Enter your name"
              value={userData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-groups">
            <label className="form-label">Email</label>
            <input
              className="form-controls"
              name="email"
              placeholder="Enter your email"
              value={userData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-groups">
            <label className="form-label">Phone</label>
            <input
              className="form-controls"
              name="phone_number"
              placeholder="Enter your phonenumber"
              value={userData.phone_number}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="payment-options">
          <div className="title">Payment Options<div className="title-underline"></div></div>
          <div className="form-checks">
            <input
              type="radio"
              className="form-check-inputs"
              checked={selectedPlan === 'yearly'}
              onChange={() => setSelectedPlan('yearly')}
            />
            <label className="form-check-label">Empanalment Charges ₹25000/-</label>
          </div>
        </div>

        <div className="payment-footer">
          <img id="fin-logo" alt="pay-methods" src="https://cdn.razorpay.com/static/assets/pay_methods_branding.png" className="fin-logo" />
          <div className="disclaimer">
            <p><GoShield className="icon" />All transactions are secure and encrypted. We do not share your payment information.</p>
          </div>
        </div>

        <div className="pricecard">
          <div className="pricecard-header"><h4>Amount Details</h4></div>
          <div className="pricecard-body">
            <div className="detail-row"><p className="label">Amount</p><p className="value">₹{subscriptionAmount.toFixed(2)}</p></div>
            <div className="detail-row"><p className="label">GST (18%)</p><p className="value">₹{gst.toFixed(2)}</p></div>
          </div>
          <div className="total-row"><p className="label">Total Payable</p><p className="value">₹{totalPayable.toFixed(2)}</p></div>
          <div className="pricecard-footer">
            <button className="pay-button" onClick={handlePayment} disabled={paymentProcessing}>
              {paymentProcessing ? "Processing..." : `Pay ₹${totalPayable.toFixed(2)}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerPayment;
