import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import _, { get, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RiEyeFill } from "react-icons/ri";
import {
  getAppliedUser,
  getUserSeekerCards,
  getStopAppliedUser,
} from "../../redux/slices/seekerCardSlice";
import { updateJobStatus } from "../../services/seekerService";
import { useParams } from "react-router-dom";
import { config } from "../../config/config";
import { useEffect } from "react";
import SectionLoader from "../../layout/SectionLoader/SectionLoader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../pagination/Pagination";
import { getProviderProfileDetails } from "../../redux/slices/providerCardSlice";
import '../AppliedUser/AppliedUser.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Button } from "@material-tailwind/react";




export const AppliedUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const seekerCardsArray = useSelector((state) =>
    _.get(state, "reducer.seekerCard", {})
  );

  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const userProfile = _.get(provoiderCardsArray, "profile", {});

  useEffect(() => {
    if (_.get(userDetail, "user.id", "")) {
      dispatch(getProviderProfileDetails(_.get(userDetail, "user.id", "")));
    }
  }, [dispatch]);

  const handleView = async (data) => {
    if (_.get(userProfile, "is_subscribed", false)) {
      if (data?.card_id) {
        const url = `/providerdetail/${data?.card_id}?userId=${_.get(data, "user_id", "")}`;
        window.open(url, '_blank');
        // navigate(
        //   `/providerdetail/${data?.card_id}?userId=${_.get(
        //     data,
        //     "user_id",
        //     ""
        //   )}`
        // );
        // dispatch(getStopAppliedUser());
      } else {
        Swal.fire({
          icon: "info",
          title: "Oops..",
          text: "Provider card is not available",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
        });
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "You are not subscriber",
        text: "You must first take subscription.",
        showCloseButton: true,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
      }).then((res) => {
        if (res.value) {
          navigate("/price", { target: '_blank' });
        }
      });
    }
  };
  const getCardColor = (statusKey) => {
    switch (statusKey) {
      // case "RJT":
      //   return "card--rejected";
      // case "JBO":
      //   return "card--job-offered";
      // Add more cases for other status values and corresponding classes
      default:
        return "";
    }
  };  

  let PageSize = 10;

  const handlePageChange = async (pageC) => {
    setCurrentPage(pageC);
    await dispatch(
      getAppliedUser(seekerCardsArray?.appliedUser?.results?.job_id, pageC)
    );
  };
  return (
    <>
      {_.get(seekerCardsArray, "isLoading", false) ? (
        <SectionLoader />
      ) : (
        <>
          <div className="profile-form m-2 applied-user-container">
            <TableContainer component={Paper} className=" overflow-hidden bg-lightblue">
              <Table className="table table-bordered table-striped">
                <TableHead className="thead-dark">
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Applied Date</TableCell>
                    <TableCell>Job Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(seekerCardsArray?.appliedUser?.results?.data, (seekerObj, i) => {
                    const isActive = _.get(seekerObj, "is_active", false);
                    let stat = _.get(seekerObj, "status.key", "");
                    let cardColorClass = getCardColor(stat);
                    return (
                      <TableRow key={i} className={`table-${cardColorClass}`}>
                        <TableCell>
                          <strong>{_.get(seekerObj, "username", "")}</strong>
                          {/* <Button className=" mx-2" > */}
                          {/* <RiEyeFill className="mx-3 cursor-pointer"  /> */}
                          {/* </Button> */}
                          <span
                            className="mx-2"
                            style={{ cursor: "pointer", color: "#007bff" }}
                            onClick={() => handleView(seekerObj)}
                          >
                            <RiEyeFill />
                          </span>
                        </TableCell>
                        <TableCell>{_.get(seekerObj, "email", "")}</TableCell>
                        <TableCell>{moment(_.get(seekerObj, "applied_at", "")).format("DD MMMM, YYYY")}</TableCell>
                        <TableCell>
                          <select
                            className="custom-select profile-card__select"
                            onChange={async (e) => {
                              updateJobStatus(_.get(seekerObj, "application_id", ""), { status: e.target.value });
                              Swal.fire({
                                icon: "success",
                                title: "Job Status Updated",
                                showCloseButton: true,
                                confirmButtonText: '<i className="fa fa-thumbs-up"></i> Ok!',
                              });
                              stat = _.get(seekerObj, "status.key", "");
                            }}
                          >
                            <option value="SHT" selected={stat === "SHT"}>
                              Shortlisted
                            </option>
                            <option value="APL" selected={stat === "APL"}>
                              Applied
                            </option>
                            <option value="OTS" selected={stat === "OTS"}>
                              Online Test Scheduled
                            </option>
                            <option value="OTA" selected={stat === "OTA"}>
                              Online Test Submitted
                            </option>
                            <option value="CNC" selected={stat === "CNC"}>
                              Call Not Connected
                            </option>
                            <option value="DCP" selected={stat === "DCP"}>
                              Document/CV Pending
                            </option>
                            <option value="ITS" selected={stat === "ITS"}>
                              Ready For Interview
                            </option>
                            <option value="ABI" selected={stat === "ABI"}>
                              Absent for Interview
                            </option>
                            <option value="JBO" selected={stat === "JBO"}>
                              Job Offered
                            </option>
                            <option value="RJT" selected={stat === "RJT"}>
                              Rejected
                            </option>
                          </select>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

            </TableContainer>

          </div>

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={seekerCardsArray.appliedUser.count || 0}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </>
      )}
    </>
  );
};