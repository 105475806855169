import React from "react";
// import { Audio } from "react-loader-spinner";
import { Triangle } from "react-loader-spinner";

const SectionLoader = () => {
  return (
    <section
      className="ftco-section"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Triangle
        height="150"
        width="300"
        color="#4fa94d"
        ariaLabel="triangle-loading"
        wrapperStyle={{justifyContent:"center",alignItems:'center'}}
        wrapperClassName=""
        visible={true}
      />
    </section>
  );
};

export default SectionLoader;
