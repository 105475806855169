import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Modal } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Signup from "../Sign-up/Signup";
import { TfiClose } from "react-icons/tfi";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { AuthSign } from "../../../redux/slices/authSlice";
import VerifyPhoneOTP from "../../VerifyPhoneOTP/VerifyPhoneOTP";
import { modalManage } from "../../../redux/slices/authModalHandleSlice";
import SignupPopup from "../Sign-up/SignupPopup";

const Signin = () => {
  const dispatch = useDispatch();
  const [signupShow, setSignupShow] = useState(false);
  const navigate = useNavigate();
  const modalHandle = useSelector((state) =>
    _.get(state, "reducer.authModalHandle.modalHandle", {})
  );
  const [signupPopupOpen, setSignupPopupOpen] = useState(false); // Add state for signup popup

  const handleClose = () => {
    dispatch(modalManage("allModalClose"));
  };

  const handleForgotPass = () => {
    dispatch(modalManage("forgot"));
  };

  const handleBack = () => {
    dispatch(modalManage("login"));
  };

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email id")
      .required("Email cannot be empty"),
    password: Yup.string()
      .min(2, "Password is too short!")
      .max(50, "Password is too long!")
      .required("Password cannot be empty"),
  });

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const ForgotPass = Yup.object().shape({
    phone: Yup.string()
      .required("Phone number can not be empty")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long"),
  });

  const onSubmit = async (values, setSubmitting, resetForm) => {
    let reqData;
    reqData = {
      email: _.get(values, "email", ""),
      password: _.get(values, "password", ""),
    };
    await dispatch(AuthSign(reqData, navigate));
  };

  return (
    <>
      <Modal
        open={modalHandle.loginModal}
        onClose={handleClose}
        centered
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-8 rounded-lg w-96">
            <div className="flex justify-between items-center">
              <h5 className="text-7xl font-semibold">Login</h5>
              <button className="p-2" onClick={handleClose}>
                <TfiClose />
              </button>
            </div>
            <div>
              <Formik
                initialValues={
                  modalHandle.forgotModal
                    ? { phone: "" }
                    : { email: "", password: "" }
                }
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  onSubmit(values, setSubmitting, resetForm)
                }
                validationSchema={
                  !modalHandle.forgotModal ? SigninSchema : ForgotPass
                }
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <>
                      <div className="form-group mt-4">
                        {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label> */}
                        <Field
                          name="email"
                          type="email"
                          className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          placeholder="Enter your email"
                        />
                        {errors.email && touched.email && <div className="text-red text-base pl-2">{errors.email}</div>}
                      </div>
                      <div className="form-group mt-4">
                        {/* <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label> */}
                        <Field
                          name="password"
                          type="password"
                          className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                          placeholder="Enter your password"
                        />
                        {errors.password && touched.password && <div className="text-red text-base pl-2">{errors.password}</div>}
                      </div>
                      <div className="form-group mt-4">
                        <Button
                          variant="text"
                          className="text-darkslateblue-100 "
                          onClick={handleForgotPass}
                        >
                          Forgot password
                        </Button>
                      </div>
                      <div className="form-group mt-4">
                        <Button
                          type="submit"
                          className="w-full bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white py-3 rounded-md"
                          disabled={isSubmitting || Object.keys(errors).length > 0}
                        >
                          {isSubmitting ? "Please wait..." : "Submit"}
                        </Button>
                      </div>
                      <Button
                        variant="text"
                        onClick={() => setSignupPopupOpen(true)}
                        className="hover:text-white mt-4 transition-colors duration-300 bg-white text-darkslateblue-100 block w-full text-center "
                      >
                        Don't have an account ?
                      </Button>
                    </>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      {modalHandle.signupModal && (
        <Signup
          signupShow={signupShow}
          setSignupShow={setSignupShow}
        />
      )}
      {modalHandle.forgotModal && <VerifyPhoneOTP />}

      <div>
        <SignupPopup open={signupPopupOpen} onClose={() => setSignupPopupOpen(false)} />
      </div>
    </>
  );
};
  
export default Signin;
