import React, { useState } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";

const Career = () => {
  // State to toggle job cards display
  const [showJobs, setShowJobs] = useState(false);

  // Sample job data
  const jobOpenings = [
    // {
    //   title: "ROR Developer",
    //   experience: "3-6 Years",
    //   location: "Ahmedabad",
    // },
    // {
    //   title: "Python Developer",
    //   experience: "3-6 Years",
    //   location: "Ahmedabad",
    // },
    {
      title: "Marketing Executive",
      experience: "0-2 Years in MFI",
      location: "Ahmedabad,Gujarat",
    },
    // {
    //   title: "QA Engineer",
    //   experience: "1-2 Years",
    //   location: "Ahmedabad",
    // },
  ];

  // Function to open Gmail compose
  const openGmailCompose = () => {
    const receiverEmail = "resolab@enliven-solutions.com";
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
    window.open(mailtoLink);
  };

  return (
    <>
      <div className="lg:w-4/4 w-[95%] relative bg-white h-auto overflow-hidden text-black flex flex-col gap-[40px]  text-center">
        <div className="w-full rounded-11xl pb-10 overflow-hidden bg-[#eaeaea]">
          <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">
            BECOME A PART OF OUR TEAM
          </h1>
          <div className="flex flex-col items-center justify-center">
            <div className="lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-5 px-36 sm:px-8 gap-[10px] bg-whitesmoke rounded-11xl z-[1]">
              <p className="text-[1.5rem] sm:text-[1rem] text-center">
                We are looking for passionate people to join us on our mission
                to bring all microfinance resources and opportunities to our
                platform. We value flat hierarchy, clear communication, full
                ownership, and responsibility.
              </p>
              <p className="mt-2 text-[1.5rem] sm:text-[1rem]">
                Please mail your CV to{" "}
                <a
                  href="mailto:hr@resolabindia.com"
                  className="text-blue-600 underline"
                >
                  hr@resolabindia.com
                </a>
              </p>
              <Button
                className="rounded-3xs font-semibold py-[12px] mt-0 px-[30px] text-[1rem]"
                onClick={() => setShowJobs(!showJobs)}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "10px",
                  background: "#015498",
                  borderRadius: "10px",
                  "&:hover": { background: "#015498" },
                }}
              >
                Current Opening
              </Button>
              {showJobs && (
                <div className="job-list flex flex-wrap justify-center gap-4 mt-5 mb-5">
                  {jobOpenings.length > 0 ? (
                    jobOpenings.map((job, index) => (
                      <Card
                        key={index}
                        sx={{
                          width: "300px",
                          background: "#f5f5f5",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold", marginBottom: "10px", color : "darkblue" }}
                          >
                            {job.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.black"
                            sx={{ marginBottom: "5px" }}
                          >
                            Experience: {job.experience}
                          </Typography>
                          <Typography variant="body2" >
                            Location: {job.location}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      There Is No Current Opening Here
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
