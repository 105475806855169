import { useState } from "react";
import Footer from "../../components/footer";
import NormalTop from "../../components/normalTop";

const FAQ = () => {
    const [activeQuestion, setActiveQuestion] = useState(0);

    const handleClick = (index) => {
        setActiveQuestion(index);
    };

    const questions = [
        {
            id: 1,
            question: "What is Resolab (Resource Lab)?",
            answer: "Resolab is a hiring platform exclusively for Micro Finance Institutions  (MFIs)."
        },
        {
            id: 2,
            question: "How it works?",
            answer: "Resolab is designed to connect human resources of Micro Finance Sector to prospective employer."
        },
        {
            id: 3,
            question: "Is Onboarding to Resolab is free?",
            answer: "Yes, individuals working for MFIs can create profile (ResoCard) wothout any charge. Similarly MFIs can also post their requirement for free."
        },
        {
            id: 4,
            question: "Who is Resource Provider?",
            answer: "Individuals working for MFIs or ready to work for MFIs are Resource Providers."
        },
        {
            id: 5,
            question: "Who is Resource Seeker?",
            answer: "MFIs (Institutions looking for hiring new employees) are Resource Seekers."
        },
        {
            id: 6,
            question: "What is Resolab Partnership Program?",
            answer: "Through Resolab Partnership Program one can join as business associate and can earn."
        }
    ]

    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] mq800:gap-[0px] mq1150:gap-[50px]">
            <NormalTop heading="Frequently Asked Questions" />
            <section className="self-stretch flex flex-row items-start justify-center py-10 px-5 box-border">
                <div className="flex flex-row flex-wrap items-start justify-center lg:w-3/4 [row-gap:20px]">
                    {questions.map((question , index) => (
                        <div
                            key={index}
                            className={`rounded-xl  min-w-full  ${activeQuestion === index ? "bg-forestgreen text-white" : "bg-whitesmoke text-black"
                                } flex flex-col items-start justify-start px-5 py-5 md:p-10 lg:p-5 cursor-pointer`}
                            onClick={() => handleClick(index)}
                        >
                            <div className="flex flex-row items-center justify-between w-full text-3xl">
                                <b className="relative leading-[150%] ">
                                    {question.question}
                                </b>
                                <img
                                    className="w-5 h-5"
                                    alt=""
                                    src={activeQuestion === index ? "/group.svg" : "/fi-9312231.svg"}
                                />
                            </div>
                            {activeQuestion === index && (
                                <div className="w-full text-3xl md:text-2xl lg:text-3xl leading-relaxed">
                                    {question.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            <Footer
                image58="/image-581@2x.png"
                image60="/image-60@2x.png"
                image581="/image-58-1@2x.png"
                image601="/image-60@2x.png"
            />
        </div>
    );
};

export default FAQ;
