import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import OTPInput from "otp-input-react";
import { Button } from "@mui/material";
import { seekerSignUpService } from "../../../services/authService";
import "./SignupPage.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SeekerSignup = () => {
  const [companyName, setCompanyName] = useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = useState("");
  const [companyRegisterStateId, setCompanyRegisterStateId] = useState("");
  const [companyRegisterStateName, setCompanyRegisterStateName] = useState("");
  const [opratingRegisterStateId, setOpratingRegisterStateId] = useState("");
  const [opratingRegisterStateName, setOpratingRegisterStateName] =
    useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(-1);
  const [imageUrl, setImageUrl] = useState("");
  const [showFileUpload, setShowFileUpload] = useState(true);

  const [error, setError] = useState("");

  const [getOtpFlag, setGetOtpFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerify, setOtpVerify] = useState("");
  const [verificationResponse, setVerificationResponse] = useState("");
  const [sendOTPloading, setSendOTPloading] = useState(false);
  const [otpFormVisible, setOtpFormVisible] = useState(false);

  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});

  const suggestionBoxRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const provoiderCardsArray = useSelector((state) =>
    _.get(state, "reducer.provoiderCard", {})
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const handleChanges = (field, value) => {
    if (field === "password") {
      setPassword(value);
    } else if (field === "confirmPassword") {
      setConfirmPassword(value);
    }
  };
  const handleKeyDown = (e) => {
    const { keyCode } = e;

    if (keyCode === 40) {
      setCurrentFocus((prevFocus) =>
        prevFocus < suggestions.length - 1 ? prevFocus + 1 : prevFocus
      );
    } else if (keyCode === 38) {
      setCurrentFocus((prevFocus) =>
        prevFocus > 0 ? prevFocus - 1 : prevFocus
      );
    } else if (keyCode === 13 && currentFocus > -1) {
      e.preventDefault();
      handleClickSuggestion(suggestions[currentFocus]);
    }
  };

  const handleClickOutside = (event) => {
    if (
      suggestionBoxRef.current &&
      !suggestionBoxRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setSuggestions([]);
      setCurrentFocus(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const fetchCompanyNames = async (name) => {
    try {
      const response = await axiosInstance.get(
        `/seeker/company-name-suggestions?companyName=${name}`
      );
      setSuggestions(response.data || []);
    } catch (error) {
      console.error("Error fetching company names:", error);
    }
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;

    // Regular expression to allow only alphabets and spaces
    const validCompanyNameRegex = /^[A-Za-z\s]*$/;

    // Check if the input is valid
    if (validCompanyNameRegex.test(value) || value === "") {
      setCompanyName(value); // Only update the state if it's valid
      setImageUrl(""); // Reset imageUrl if typing a new name
      setShowFileUpload(true); // Show file input for new company name

      if (!value) {
        setSuggestions([]); // Clear suggestions if the field is empty
        return;
      }

      // Debounced request to fetch company names
      clearTimeout(window.debounceTimer);
      window.debounceTimer = setTimeout(() => {
        fetchCompanyNames(value);
      }, 500);
    } else {
      // Show error if the input contains invalid characters
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Company name must only contain letters and spaces.",
      }));
    }
  };

  const handleClickSuggestion = async (suggestion) => {
    setCompanyName(suggestion); // Set the selected suggestion as the company name
    setSuggestions([]); // Clear the suggestions list

    // Clear errors and mark the field as touched
    setErrors((prevErrors) => ({
      ...prevErrors,
      companyName: "", // Clear any existing error for companyName
    }));
    setTouchedFields((prevFields) => ({
      ...prevFields,
      companyName: true, // Mark the field as touched
    }));

    try {
      // Fetch the company logo URL
      const response = await axiosInstance.get(`/seeker/fetch_company_logo`, {
        params: { companyName: suggestion },
      });

      // Set the image URL and disable file upload
      setImageUrl(response.data.profile_pic_url);
      setShowFileUpload(false); // Disable the file input
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file)); // Display image preview
      setShowFileUpload(true); // Show the file upload preview
    }
  };

  const handleBlur = (field, value) => {
    if (field === "companyName") {
      if (value.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          companyName: "Company name is required.", // If the field is empty, show required error
        }));
      } else {
        // Regular expression to allow only alphabets and spaces
        const validCompanyNameRegex = /^[A-Za-z\s]*$/;

        if (!validCompanyNameRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            companyName: "Company name must only contain letters and spaces.", // Invalid input error
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            companyName: "", // Clear any error if input is valid
          }));
        }
      }

      setTouchedFields((prevFields) => ({
        ...prevFields,
        companyName: true,
      }));
    }

    if (field === "dateOfIncorporation") {
      const selectedDate = new Date(value);
      const today = new Date();

      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfIncorporation: "Date of Incorporation is required.",
        }));
      } else if (selectedDate > today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfIncorporation: "Date of Incorporation cannot be in the future.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfIncorporation: "",
        }));
      }

      setTouchedFields((prevFields) => ({
        ...prevFields,
        dateOfIncorporation: true,
      }));
    }

    if (field === "companyRegisterState") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          companyRegisterState:
            "Please select a valid state for Company Registered Office.",
        }));
        setTouchedFields((prevFields) => ({
          ...prevFields,
          companyRegisterState: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          companyRegisterState: "",
        }));
      }
    }

    if (field === "opratingRegisterState") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          opratingRegisterState:
            "Please select a valid state for Target User State.",
        }));
        setTouchedFields((prevFields) => ({
          ...prevFields,
          opratingRegisterState: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          opratingRegisterState: "",
        }));
      }
    }

    if (field === "firstName") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name is required.",
        }));
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        firstName: true,
      }));
    }

    if (field === "lastName") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name is required.",
        }));
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        lastName: true,
      }));
    }

    // Validate email
    if (field === "email" && !value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        email: true,
      }));
    } else if (field === "email" && !/\S+@\S+\.\S+/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email.",
      }));
    }

    if (field === "phoneNumber") {
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number is required.",
        }));
      } else if (!/^\d{10}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
      setTouchedFields((prevFields) => ({
        ...prevFields,
        phoneNumber: true,
      }));
    }

    if (field === "password") {
      if (!value.trim()) {
        // Show only "Password is required" when the field is empty
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password is required.",
        }));
      } else {
        // Initialize error messages
        const errorMessages = [];

        // Validation rules
        if (!/[A-Z]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one uppercase letter."
          );
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one special character."
          );
        }
        if (!/\d/.test(value)) {
          errorMessages.push("Password must contain at least one number.");
        }
        if (!/[a-zA-Z]/.test(value)) {
          errorMessages.push(
            "Password must contain at least one alphabetical letter."
          );
        }
        if (value.length < 8) {
          errorMessages.push("Password must be at least 8 characters long.");
        }

        // Set errors or clear them
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.join(" "),
        }));
      }

      setTouchedFields((prevFields) => ({
        ...prevFields,
        password: true,
      }));
    }

    // Validate confirm password
    if (field === "confirmPassword" && !value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Confirm password is required.",
      }));
      setTouchedFields((prevFields) => ({
        ...prevFields,
        confirmPassword: true,
      }));
    } else if (field === "confirmPassword" && value !== password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    }
  };

  const handleChange = (field, value) => {
    if (field === "companyName") {
      setCompanyName(value);
      if (errors.companyName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          companyName: "",
        }));
      }
    }

    if (field === "dateOfIncorporation") {
      setDateOfIncorporation(value);
      if (errors.dateOfIncorporation) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfIncorporation: "",
        }));
      }
    }

    if (field === "firstName") {
      setFirstName(value);
      if (!/^[A-Za-z]*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
    }

    if (field === "lastName") {
      setLastName(value);
      if (!/^[A-Za-z]*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last name must contain only letters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
    }

    if (field === "email") {
      setEmail(value);
      if (errors.email) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
    if (field === "phoneNumber") {
      setPhoneNumber(value);

      // Allow only digits
      if (!/^\d*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Phone number must contain only digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }

    if (field === "password") {
      setPassword(value);

      // If the password field is empty, show only the "Password is required" error
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password is required.",
        }));
        return;
      }

      // Initialize error messages
      const errorMessages = [];

      // Validation rules
      if (!/[A-Z]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one uppercase letter."
        );
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one special character."
        );
      }
      if (!/\d/.test(value)) {
        errorMessages.push("Password must contain at least one number.");
      }
      if (!/[a-zA-Z]/.test(value)) {
        errorMessages.push(
          "Password must contain at least one alphabetical letter."
        );
      }
      if (value.length < 8) {
        errorMessages.push("Password must be at least 8 characters long.");
      }

      // Set errors or clear them
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: errorMessages.join(" "),
      }));
    }

    if (field === "confirmPassword") {
      setConfirmPassword(value);
      if (errors.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }
    }
  };

  const handleCompanyRegisterState = (stateId, stateName) => {
    setCompanyRegisterStateId(stateId);
    if (errors.companyRegisterState) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyRegisterState: "",
      }));
    }
  };

  const handleOpratingRegisterState = (stateId, stateName) => {
    setOpratingRegisterStateId(stateId);
    if (errors.opratingRegisterState) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        opratingRegisterState: "",
      }));
    }
  };

  const checkEmailAndPhoneNumberUniqueness = async () => {
    try {
      const response = await axiosInstance.post("/account/check/", {
        email: email,
        phone_number: phoneNumber,
      });

      console.log(response);
      if (
        response.message === "Email and phone number are already registered"
      ) {
        toast.error("Email and phone number are already registered.");
        return false;
      }

      if (response.message === "Email is already registered") {
        toast.error("Email is already registered");
        return false;
      }

      if (response.message === "Phone number is already registered") {
        toast.error("Phone number is already registered");
        return false;
      }

      return true;
    } catch (error) {
      // Log the full error to the console for debugging
      console.error("Error occurred while checking email/phone:", error);

      // You can display more specific error details
      if (error.response) {
        // The request was made, but the server responded with an error
        console.error("Response error:", error.response.data);
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong"}`
        );
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Request error:", error.request);
        toast.error("No response received from server.");
      } else {
        // Other errors
        console.error("Error message:", error.message);
        toast.error("An unknown error occurred.");
      }

      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for form validation errors
    if (Object.values(errors).some((err) => err)) {
      toast.error("Please fill all the form fields correctly.");
      return;
    }

    // First check email and phone number uniqueness
    const isUnique = await checkEmailAndPhoneNumberUniqueness();
    if (!isUnique) {
      return; // Stop further execution if the email or phone number is already taken
    }

    setOtpFormVisible(true);
    setGetOtpFlag(false);
    await getOtp(); // Call function to send OTP after checking uniqueness
  };

  const getOtp = async () => {
    setSendOTPloading(true);

    const phonePattern = /^[6-9]\d{9}$/;
    if (!phonePattern.test(phoneNumber)) {
      toast.error("Invalid phone number!");
      setSendOTPloading(false);
      return;
    }

    const formatPh = "+91" + phoneNumber;

    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          { size: "invisible" }
        );
        await window.recaptchaVerifier.render();
      }

      const res = await signInWithPhoneNumber(
        auth,
        formatPh,
        window.recaptchaVerifier
      );
      if (res.verificationId) {
        setVerificationResponse(res);
        toast.success("OTP sent successfully!");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred.");
    } finally {
      setSendOTPloading(false);
    }
  };

  const onOTPVerify = async () => {
    if (otp.length === 6) {
      setLoading(true);
      try {
        const myres = await verificationResponse.confirm(otp);
        if (myres) {
          toast.success("OTP verified successfully!");
          setOtpFormVisible(false);
          await handleFormSubmission();
        }
      } catch (error) {
        toast.error("Invalid OTP!");
      }
      setLoading(false);
    } else {
      toast.error("Please enter the complete OTP.");
    }
  };

  const resendOtp = async () => {
    setSendOTPloading(true);
    await getOtp(); // Reuse the getOtp method to send the OTP again.
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setOtp(""); // Clear the OTP field when phone number changes
  };

  const handleFormSubmission = async () => {
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("date_of_birth", dateOfIncorporation);
    formData.append("company_register_state", companyRegisterStateName);
    formData.append("oprating_register_state", opratingRegisterStateName);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("password", password);
    formData.append("password2", confirmPassword);

    // If URL is available, send URL
    if (imageUrl && !showFileUpload) {
      formData.append("profile_pic_url", imageUrl);
    }
    // If file is selected, send the file
    else if (showFileUpload) {
      const fileInput = document.getElementById("image");
      if (fileInput.files[0]) {
        formData.append("profile_pic_url", fileInput.files[0]);
      }
    }

    try {
      const response = await seekerSignUpService(formData);
      if (response.status === 201) {
        Swal.fire({
          title: "Signup Failed",
          text: "You have successfully signed up.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Signup Successful!",
          text: response.data?.message || "Unknown error occurred.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/loginpage");
        });
      }
    } catch (error) {
      console.error("Error Details:", error.response || error.message || error);

      // Check if both email and phone number are already used
      const isEmailUsed =
        error.response?.data?.errors?.email?.[0] ===
        "This field must be unique.";
      const isPhoneNumberUsed =
        error.response?.data?.errors?.phone_number?.[0] ===
        "This field must be unique.";

      // Display custom error messages based on the validation errors
      if (isEmailUsed && isPhoneNumberUsed) {
        Swal.fire({
          title: "Signup Failed",
          text: "Email and phone number are already used.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else if (isEmailUsed) {
        Swal.fire({
          title: "Signup Failed",
          text: "Email is already used.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else if (isPhoneNumberUsed) {
        Swal.fire({
          title: "Signup Failed",
          text: "Phone number is already used.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        // Default error message for other errors
        Swal.fire({
          title: "Signup Failed",
          text: "There was an error during signup. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // const handleCompanyRegisterState = (stateId, stateName) => {
  //   setCompanyRegisterStateId(stateId);
  //   setCompanyRegisterStateName(stateName);
  // };

  // const handleOpratingRegisterState = (stateId, stateName) => {
  //   setOpratingRegisterStateId(stateId);
  //   setOpratingRegisterStateName(stateName);
  // };

  const navigateToProviderSignup = () => {
    navigate("/signuppage", { state: { type: "provider" } });
  };

  const navigateToLogin = () => {
    navigate("/loginpage");
  };

  return (
    <>
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2 className="signup-title">SIGNUP</h2>
        {error && <p className="error-message">{error}</p>}

        <div className="details-box">
          <h3 className="details-title">Company Details</h3>

          <div className="signup-form-group">
            <div className="form-field" style={{ position: "relative" }}>
              <label htmlFor="companyName">Company Name</label>
              <input
                id="companyName"
                name="companyName"
                type="text"
                placeholder="Enter company name"
                required
                value={companyName}
                onChange={handleCompanyNameChange}
                onKeyDown={handleKeyDown}
                ref={inputRef} // Attach ref to input
                onBlur={(e) => handleBlur("companyName", e.target.value)}
              />
              {touchedFields.companyName && errors.companyName && (
                <p className="error-text">{errors.companyName}</p>
              )}
              {suggestions.length > 0 && (
                <div
                  className="autocomplete-items"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    zIndex: 1000,
                  }}
                  ref={suggestionBoxRef} // Attach ref to suggestion box
                >
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      onClick={() => handleClickSuggestion(suggestion)}
                      className={
                        currentFocus === index ? "autocomplete-active" : ""
                      }
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        backgroundColor:
                          currentFocus === index ? "#f0f0f0" : "transparent",
                      }}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="signup-form-group d-flex">
            <div className="form-field">
              <label>Company Logo</label>
              {showFileUpload ? (
                // If file upload is allowed, show the file input
                <input
                  id="image"
                  name="image"
                  type="file"
                  required
                  onChange={handleFileChange} // File select karne ka handler
                />
              ) : (
                // If image URL is available, display the image
                <div>
                  <img
                    src={`${imageUrl}`} // Construct the full URL to the image
                    alt="Company Logo"
                    style={{
                      width: "100px",
                      height: "auto",
                      marginTop: "10px",
                    }} // Adjust size as needed
                  />
                  <input
                    id="image"
                    name="image"
                    type="text"
                    value={imageUrl} // Display image name as text
                    readOnly
                    placeholder="Profile Pic URL"
                    required
                  />
                </div>
              )}
            </div>

            <div className="form-field">
              <label>Date of Incorporation</label>
              <input
                id="dateOfIncorporation"
                name="dateOfIncorporation"
                type="date"
                required
                value={dateOfIncorporation}
                onChange={(e) =>
                  handleChange("dateOfIncorporation", e.target.value)
                }
                onBlur={(e) =>
                  handleBlur("dateOfIncorporation", e.target.value)
                }
              />
              {touchedFields.dateOfIncorporation &&
                errors.dateOfIncorporation && (
                  <p className="error-text">{errors.dateOfIncorporation}</p>
                )}
            </div>
          </div>

          <div className="signup-form-group">
            <div className="form-field">
              <label>Company Registered Office</label>
              <select
                name="companyRegisterState"
                required
                value={companyRegisterStateId}
                onChange={(e) =>
                  handleCompanyRegisterState(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text
                  )
                }
                onBlur={(e) =>
                  handleBlur("companyRegisterState", e.target.value)
                }
              >
                <option value="">Select State</option>
                {_.map(_.get(provoiderCardsArray, "states", []), (stateObj) => (
                  <option
                    key={_.get(stateObj, "id", "")}
                    value={_.get(stateObj, "id", "")}
                  >
                    {_.get(stateObj, "state", "")}
                  </option>
                ))}
              </select>
              {touchedFields.companyRegisterState &&
                errors.companyRegisterState && (
                  <p className="error-text">{errors.companyRegisterState}</p>
                )}
            </div>

            <div className="form-field">
              <label>Target User State</label>
              <select
                name="opratingRegisterState"
                required
                value={opratingRegisterStateId}
                onChange={(e) =>
                  handleOpratingRegisterState(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text
                  )
                }
                onBlur={(e) =>
                  handleBlur("opratingRegisterState", e.target.value)
                }
              >
                <option value="">Select State</option>
                {_.map(_.get(provoiderCardsArray, "states", []), (stateObj) => (
                  <option
                    key={_.get(stateObj, "id", "")}
                    value={_.get(stateObj, "id", "")}
                  >
                    {_.get(stateObj, "state", "")}
                  </option>
                ))}
              </select>
              {touchedFields.opratingRegisterState &&
                errors.opratingRegisterState && (
                  <p className="error-text">{errors.opratingRegisterState}</p>
                )}
            </div>
          </div>
        </div>

        <div className="details-box">
          <h3 className="details-title">Contact Person Details</h3>
          <div className="signup-form-group">
            <div className="form-field">
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                onBlur={(e) => handleBlur("firstName", e.target.value)}
                required
              />
              {touchedFields.firstName && errors.firstName && (
                <p className="error-text">{errors.firstName}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                onBlur={(e) => handleBlur("lastName", e.target.value)}
                required
              />
              {touchedFields.lastName && errors.lastName && (
                <p className="error-text">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div className="signup-form-group">
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => handleChange("email", e.target.value)}
                onBlur={(e) => handleBlur("email", e.target.value)}
                required
              />
              {touchedFields.email && errors.email && (
                <p className="error-text">{errors.email}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="phone">Phone No</label>
              <input
                id="phone"
                name="phone"
                type="text"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => handleChange("phoneNumber", e.target.value)}
                onBlur={(e) => handleBlur("phoneNumber", e.target.value)}
                required
              />
              {touchedFields.phoneNumber && errors.phoneNumber && (
                <p className="error-text">{errors.phoneNumber}</p>
              )}
            </div>
          </div>
          <div className="signup-form-group">
            <div className="form-field">
              <label htmlFor="password">Password</label>
              <div className="input-container">
                <input
                  id="password"
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  onBlur={(e) => handleBlur("password", e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="eye-icon"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {touchedFields.password && errors.password && (
                <p className="error-text">{errors.password}</p>
              )}
            </div>
          </div>
          <div className="signup-form-group">
            <div className="form-field">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <div className="input-container">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Enter confirm password"
                  value={confirmPassword}
                  onChange={(e) =>
                    handleChange("confirmPassword", e.target.value)
                  }
                  onBlur={(e) => handleBlur("confirmPassword", e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="eye-icon"
                  onClick={() =>
                    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                  }
                >
                  {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {touchedFields.confirmPassword && errors.confirmPassword && (
                <p className="error-text">{errors.confirmPassword}</p>
              )}
            </div>
          </div>{" "}
        </div>

        <button type="submit" className="signup-button">
          SUBMIT
        </button>

        <div id="recaptcha-container"></div>

        <div className="navigate-link">
          If you are a Professional, then{" "}
          <span onClick={navigateToProviderSignup} className="link">
            <b>click here</b>
          </span>
          .
        </div>

        <div className="loginpage-link">
          Already have an account ?{" "}
          <span onClick={navigateToLogin} className="link">
            <b>Login</b>
          </span>
          .
        </div>
      </form>

      <Modal
        isOpen={otpFormVisible}
        onRequestClose={() => setOtpFormVisible(false)}
        ariaHideApp={false}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
          {/* Close Button (X) */}
          <span
            className="absolute top-2 right-2 text-10xl cursor-pointer text-gray-700"
            onClick={() => setOtpFormVisible(false)}
          >
            &times;
          </span>

          <div className="form-group py-2">
            <label htmlFor="phone" className="block text-sm font-medium mb-1">
              PHONE NUMBER
            </label>
            <div className="flex items-center">
              <PhoneInput
                className="w-full bg-[#F7F7F7] h-10 px-2 rounded-md border focus:outline-none focus:ring-2 focus:ring-indigo-500"
                defaultCountry="IN"
                placeholder="Enter Your Phone Number"
                value={phoneNumber}
                disabled
              />
              {getOtpFlag && (
                <div
                  id="buttonDiv"
                  className="btnOTP cursor-pointer px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  onClick={getOtp}
                >
                  {sendOTPloading ? "OTP sending..." : "Get OTP"}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="otp" className="block text-xl font-medium mb-1">
              ENTER OTP
            </label>
            <OTPInput
              className="w-full bg-[#F7F7F7] py-1 h-10 px-2 text-2xl rounded-md border border-blue-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
            />
          </div>

          <div className="flex justify-center w-full mt-5">
            <Button
              onClick={onOTPVerify}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </Button>
          </div>

          <div className="flex justify-center mt-4 text-xs text-gray-500">
            <span>
              Didn't get OTP?{" "}
              <span
                onClick={resendOtp}
                className="text-blue-500 cursor-pointer"
              >
                Resend OTP
              </span>
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SeekerSignup;
